/* eslint-disable react/prop-types */
import { Autocomplete, Chip, FormHelperText, Grid2 as Grid, MenuItem, TextField, Typography } from '@mui/material';
import React from 'react';
import { AnyAction, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Field, Form, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { InputField } from './redux-form/InputField';
import { RenderSelectField } from './RenderSelectField';
import { UserDialogFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { getPermissionLabel, Permissions } from '../constants/Permissions';
import { emailValidation as asyncValidate, required } from '../utils/reduxFormUtils';
import { AppState } from '../types/AppState';
import { Dealer } from '../types/Dealer';
import { isCurrentUserManager, isCurrentUserGroupAdmin } from '../utils/userUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { getDateTimeString } from '../utils/dateUtils';

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginTop: '10px',
  },
  field: {
    marginBottom: '15px',
    width: '100%',
  },
});

export interface FormData {
  [UserDialogFormFields.FirstName]: string;
  [UserDialogFormFields.LastName]: string;
  [UserDialogFormFields.Email]: string;
  [UserDialogFormFields.PhoneNumber]: string;
  [UserDialogFormFields.Permissions]: string[];
  [UserDialogFormFields.LastLogin]: string;
}

interface FormDispatchProps {
  onSubmit(data: FormData): void;
}

interface StateProps {
  newMember: boolean;
  availableDealers: Dealer[];
  adminUser: boolean;
  managerUser: boolean;
  userIsAdmin: boolean;
  lastLogin?: string;
  warningText?: string;
}

type FormProps = FormDispatchProps & StateProps & InjectedFormProps<FormData>;

const UserFormComponent: React.FC<FormProps> = ({
  newMember,
  availableDealers,
  adminUser,
  managerUser,
  userIsAdmin,
  lastLogin,
  warningText,
  error,
  handleSubmit,
  onSubmit,
  change,
}: FormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const getSelectedDealers = (dealerKeys: string[]): Dealer[] | undefined =>
    availableDealers.filter((dealer: Dealer) => dealerKeys.includes(dealer.key));

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Field
        disabled={!newMember}
        autoFocus={newMember}
        autoComplete="off"
        className={classes.field}
        component={InputField}
        validate={required}
        label={`${t(I18nKeys.FieldEmail)}${newMember ? '*' : ''}`}
        name={UserDialogFormFields.Email}
      />
      {warningText && <FormHelperText>{warningText}</FormHelperText>}

      <Field
        disabled={(!newMember && !adminUser) || !!warningText}
        autoComplete="off"
        className={classes.field}
        component={InputField}
        label={t(I18nKeys.FieldPhone)}
        name={UserDialogFormFields.PhoneNumber}
      />

      <Field
        autoFocus={!newMember}
        autoComplete="off"
        multiple
        className={classes.field}
        component={RenderSelectField}
        validate={required}
        label={`${t(I18nKeys.FieldPermissions)}*`}
        name={UserDialogFormFields.Permissions}
        SelectProps={{
          displayEmpty: true,
          multiple: true,
          renderValue: (selectedValues: unknown): JSX.Element => {
            const selectedPermissions = [...(selectedValues as Permissions[])];

            if (selectedPermissions.length > 0) {
              selectedPermissions.sort();
              return (
                <div>
                  {(selectedPermissions as Permissions[]).map((selectedValue) => (
                    <Chip style={{ margin: '0px 2px' }} key={selectedValue} label={getPermissionLabel(selectedValue)} />
                  ))}
                </div>
              );
            }

            return <div style={{ height: '18px' }} />;
          },
        }}
      >
        {adminUser && (
          <MenuItem key="admin-permission" value={Permissions.Admin}>
            {getPermissionLabel(Permissions.Admin)}
          </MenuItem>
        )}

        <MenuItem key="sales-tools-permission" value={Permissions.Manager}>
          {getPermissionLabel(Permissions.Manager)}
        </MenuItem>

        <MenuItem key="sales-view-permission" value={Permissions.Sales}>
          {getPermissionLabel(Permissions.Sales)}
        </MenuItem>
      </Field>

      {availableDealers.length > 0 && (
        <Field
          name={UserDialogFormFields.Dealers}
          autoFocus={!newMember}
          className={classes.field}
          label={t(I18nKeys.UserFormFieldDealers)}
          multiple
          validate={!adminUser && managerUser && required}
          disabled={userIsAdmin}
          // eslint-disable-next-line react/no-unstable-nested-components
          component={(props: any): JSX.Element => (
            <Autocomplete
              disabled={userIsAdmin}
              multiple
              autoHighlight
              options={availableDealers}
              value={getSelectedDealers(props.input.value) || null}
              getOptionLabel={(option: Dealer): string => option.name}
              onChange={(event: any, value: Dealer[]): void => {
                change(UserDialogFormFields.Dealers, value ? value.map((val: Dealer) => val.key) : []);
              }}
              {...props} // eslint-disable-line react/jsx-props-no-spreading
              renderInput={(params: any): JSX.Element => (
                <TextField
                  {...params} // eslint-disable-line react/jsx-props-no-spreading
                  disabled={userIsAdmin}
                  required={!adminUser && managerUser}
                  variant="standard"
                  label={t(I18nKeys.UserFormFieldDealers)}
                  placeholder={t(I18nKeys.UserFormFieldDealers)}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  error={!!(props.meta.touched && props.meta.error)}
                  helperText={props.meta.touched && props.meta.error}
                />
              )}
            />
          )}
        />
      )}

      <Grid container spacing={2}>
        <Grid size={{ xs: 6 }}>
          <Field
            disabled={(!newMember && !adminUser) || !!warningText}
            autoComplete="off"
            className={classes.field}
            component={InputField}
            label={t(I18nKeys.FieldFirstName)}
            name={UserDialogFormFields.FirstName}
          />
        </Grid>
        <Grid size={{ xs: 6 }}>
          <Field
            disabled={(!newMember && !adminUser) || !!warningText}
            autoComplete="off"
            className={classes.field}
            component={InputField}
            label={t(I18nKeys.FieldLastName)}
            name={UserDialogFormFields.LastName}
          />
        </Grid>
      </Grid>

      {!newMember && (
        <Typography variant="subtitle2" color="text.secondary">
          {t(I18nKeys.UserFormLastLogin)} {lastLogin ? getDateTimeString(lastLogin) : t(I18nKeys.UserFormNeverLoggedIn)}
        </Typography>
      )}

      {!!error && <Typography className={classes.error}>{error}</Typography>}
    </Form>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): FormDispatchProps => ({
  onSubmit: (values: FormData): Promise<AnyAction> =>
    new Promise(
      (resolve, reject): AnyAction =>
        // eslint-disable-next-line no-promise-executor-return
        dispatch({
          type: `${Forms.User}_SUBMIT`,
          values,
          resolve,
          reject,
        }),
    ),
});

const mapStateToProps = (state: AppState): StateProps => {
  const {
    group: { newMember, group },
    currentUser: { user, group: usersGroup, availableDealers = [] },
    viewer: { warning: warningText },
  } = state;

  const adminUser = isCurrentUserGroupAdmin(user, usersGroup, group);
  const managerUser = isCurrentUserManager(user);
  const selector = formValueSelector(Forms.User);
  const selectedPermissions = selector(state, UserDialogFormFields.Permissions);
  const lastLogin = selector(state, UserDialogFormFields.LastLogin);
  const userIsAdmin = selectedPermissions && selectedPermissions.includes(Permissions.Admin);

  return {
    newMember,
    availableDealers,
    adminUser,
    managerUser,
    userIsAdmin,
    lastLogin,
    warningText,
  };
};

export const UserForm = reduxForm<FormData>({
  form: Forms.User,
  enableReinitialize: true,
  asyncValidate,
  asyncBlurFields: [`${UserDialogFormFields.Email}`],
})(connect(mapStateToProps, mapDispatchToProps)(UserFormComponent));
