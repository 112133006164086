import React, { useState, useEffect, useMemo, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { IdeaRoomCode, MonacoEditor, EditorApi, Language } from '@idearoom/ir-code';
import { Button, Theme } from '@mui/material';
import { BugReport } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { CustomCellEditorProps } from 'ag-grid-react';
import { formatExpression, getInitialEditorValue } from '../utils/clientDataUtils';
import { ClientDataEditor, ColumnDataType, EXPRESSION_DEBUG_DELIMITER } from '../constants/ClientData';
import { useAppSelector, useAppDispatch } from '../hooks';
import { AppState } from '../types/AppState';
import { UserPreference } from '../constants/User';
import { PreferencesFormFields } from '../constants/FormFields';
import dragHandle from '../images/dragHandle.svg';
import { saveUserPreferences } from '../ducks/currentUserSlice';
import { onEditorKeyDown } from '../utils/keyboardShortcutHandlerUtils';

const useStyles = makeStyles<Theme>(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',
  },
  debugButton: {
    marginTop: '8px',
  },
  debugButtonIcon: { marginRight: '5px' },
  editor: {
    padding: '18px',
  },
  dragHandle: {
    height: '17px',
    position: 'absolute',
    bottom: '1px',
    right: '1px',
  },
}));

export const ClientDataLargeTextEditor: React.FC<CustomCellEditorProps> = (props: CustomCellEditorProps) => {
  const classes = useStyles();
  const { colDef } = props;
  const [editorValue, setEditorValue] = useState<string | undefined>(undefined);
  const editorRef = useRef<MonacoEditor | null>(null);
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state: AppState) => state?.currentUser);
  const {
    preferences: {
      [UserPreference.ProfilePreferences]: {
        [PreferencesFormFields.Theme]: theme = undefined,
        [PreferencesFormFields.MiniMap]: minimapEnabled = false,
        editorSize: { width = '50vw', height = '30vh' } = {},
        ...otherProfilePreferences
      } = {},
    } = {},
  } = currentUser || {};
  const {
    context: {
      tableMetadata: { metadata },
    },
    column,
  } = props;

  const colId = column.getColId();
  const columnMetadata = metadata[colId || ''];
  const dataType = columnMetadata?.dataType;
  let language: Language;

  switch (dataType) {
    case ColumnDataType.Expression:
      language = Language.Expression;
      break;
    case ColumnDataType.Html:
      language = Language.Html;
      break;
    case ColumnDataType.Json:
      language = Language.JSON;
      break;
    default:
      language = Language.PlainText;
      break;
  }

  const setValueFunc = useMemo(
    () => (v: string) => {
      const { onValueChange } = props;
      setEditorValue(v);
      onValueChange(v);
    },
    [props, setEditorValue],
  );

  useEffect(() => {
    if (editorValue === undefined) {
      setValueFunc(getInitialEditorValue(props));
    }
  }, [editorValue, setValueFunc, props]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) =>
      onEditorKeyDown(e, {
        editorType: ClientDataEditor.Large,
        value: editorValue,

        insertValue: (val: string) => {
          const { editor, monaco } = (editorRef.current || {}) as EditorApi;
          const currentSelection = editor?.getSelection();
          if (!editor || !monaco || !currentSelection) return;

          const model = editor?.getModel();
          // Will allow undo/redo
          model?.pushEditOperations(
            [currentSelection],
            [
              {
                range: currentSelection,
                text: val,
              },
            ],
            () => null,
          );

          const { Selection } = monaco;
          const newSelection = editor?.getSelection();
          if (!newSelection) return;
          const { endLineNumber, endColumn } = newSelection;

          // Set selection to end of inserted text
          editor?.setSelection(new Selection(endLineNumber, endColumn, endLineNumber, endColumn));
        },

        setSelection: ({ start, end }: { start: number; end: number }) => {
          const { editor, monaco } = (editorRef.current || {}) as EditorApi;
          const currentSelection = editor?.getSelection();
          if (!editor || !monaco || !currentSelection) return;

          const { startLineNumber } = currentSelection;
          const { Selection } = monaco;
          editor?.setSelection(new Selection(startLineNumber, start, startLineNumber, end));
        },

        getSelection: () => {
          const { editor } = (editorRef.current || {}) as EditorApi;
          const { startColumn, endColumn } = editor?.getSelection() || {};
          return { start: startColumn || 0, end: endColumn || 0 };
        },
      });

    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [editorValue, setValueFunc]);

  const isExpression = colDef.type === ColumnDataType.Expression;
  const isDebugEnabled = isExpression && editorValue && editorValue.includes(EXPRESSION_DEBUG_DELIMITER);
  const valueWithoutDelimiter = isDebugEnabled ? editorValue.replace(EXPRESSION_DEBUG_DELIMITER, '') : editorValue;
  const addDebugDelimiter = (v = '') =>
    v.startsWith('=') ? v.replace('=', `=${EXPRESSION_DEBUG_DELIMITER}`) : `${EXPRESSION_DEBUG_DELIMITER}${v}`;

  return (
    <div className="ag-large-text">
      <div className={`ag-large-text-input ag-text-area ag-input-field ${classes.root}`}>
        <div className="ag-wrapper ag-input-wrapper ag-text-area-input-wrapper">
          <img alt="Drag handle" src={dragHandle} className={classes.dragHandle} />
          <IdeaRoomCode
            ref={editorRef}
            className={`ag-input-field-input ag-text-area-input ${classes.editor}`}
            width={width}
            height={height}
            value={valueWithoutDelimiter}
            language={language}
            theme={theme}
            options={{
              minimap: {
                enabled: minimapEnabled,
              },
              lineNumbers: language === Language.PlainText ? 'off' : 'on',
              folding: language !== Language.PlainText,
              overviewRulerLanes: language === Language.PlainText ? 0 : 3,
              lineDecorationsWidth: 3,
              fixedOverflowWidgets: true,
            }}
            resizable
            onChange={(v: string | undefined) => setValueFunc(isDebugEnabled ? addDebugDelimiter(v || '') : v || '')}
            onResize={({ width: newWidth, height: newHeight }: { width: string; height: string }) => {
              dispatch(
                saveUserPreferences({
                  userPreference: UserPreference.ProfilePreferences,
                  preferences: {
                    [PreferencesFormFields.Theme]: theme,
                    [PreferencesFormFields.MiniMap]: minimapEnabled,
                    editorSize: { width: newWidth, height: newHeight },
                    ...otherProfilePreferences,
                  },
                }),
              );
            }}
            customFormatters={{ [Language.Expression]: formatExpression }}
          />
        </div>
        {isExpression && (
          <Button
            className={classes.debugButton}
            variant={isDebugEnabled ? 'contained' : 'text'}
            color="info"
            size="small"
            onClick={() => {
              if (isDebugEnabled) {
                setValueFunc((editorValue || '').replace(EXPRESSION_DEBUG_DELIMITER, ''));
              } else {
                setValueFunc(addDebugDelimiter(editorValue));
              }
            }}
          >
            <BugReport className={classes.debugButtonIcon} />
            DEBUG
          </Button>
        )}
      </div>
    </div>
  );
};
