import { Permissions } from '../constants/Permissions';
import { SystemGroups } from '../constants/SystemGroups';
import { Group } from '../types/Group';
import { GroupMember } from '../types/GroupMember';
import { User } from '../types/User';
import { filterUserPermissions } from './permissionUtils';

export const isIdeaRoomUserEmail = (email: string): boolean =>
  email.toLowerCase().endsWith('@idearoominc.com') || email.toLowerCase().endsWith('@idearoom.com');

export const isIdeaRoomUser = (user?: User | GroupMember): boolean => (user ? isIdeaRoomUserEmail(user.email) : false);

export const isIdeaRoomGroup = (groupId: string): boolean => groupId === SystemGroups.IdeaRoom;

export const isCurrentUserAdmin = (user?: User): boolean =>
  user !== undefined && user.permissions !== undefined && user.permissions.includes(Permissions.Admin);

export const isCurrentUserManager = (user?: User): boolean =>
  user !== undefined &&
  user.permissions !== undefined &&
  filterUserPermissions(user.permissions).includes(Permissions.Manager);

export const isCurrentUserAdminOrManager = (user?: User): boolean =>
  isCurrentUserAdmin(user) || isCurrentUserManager(user);

export const isCurrentUserGroupAdmin = (user?: User, currentUserGroup?: Group, selectedGroup?: Group): boolean => {
  const isIdearoomGroup = selectedGroup !== undefined && isIdeaRoomGroup(selectedGroup.groupId);

  return (
    currentUserGroup !== undefined &&
    selectedGroup !== undefined &&
    ((currentUserGroup.groupId === selectedGroup.groupId &&
      user !== undefined &&
      user.permissions.includes(Permissions.Admin)) ||
      (isIdeaRoomGroup(currentUserGroup.groupId) && !isIdearoomGroup))
  );
};
