import {
  Grid2 as Grid,
  FormControl,
  Checkbox,
  Box,
  TextField,
  FormControlLabel,
  MenuItem,
  Button,
  InputAdornment,
  TextFieldProps,
  Tooltip,
  IconButton,
} from '@mui/material';
import { GppMaybe, GppGood } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import domainLoading from '../images/domainLoading.svg';
import { SiteDetailField, SiteDetail } from '../types/ClientData';
import { EmailFormat, SendEmailTo, sitesFieldsMap, SiteDetailDataFields } from '../constants/VendorData';
import { disabledSiteDetailField, hideSiteDetailField, ideaRoomOnlySiteDetailField } from '../utils/vendorDataUtils';
import { LoadingSelect } from './LoadingSelect';
import { emailFormatOptions, sendEmailsToOptions } from '../constants/Email';
import { I18nKeys } from '../constants/I18nKeys';
import { SiteInputLabel } from './SiteInputLabel';
import { useAppDispatch } from '../hooks';
import { openDialog } from '../ducks/dialogSlice';
import { Dialogs } from '../constants/Dialogs';
import { DomainStatus } from '../types/DomainVerification';
import { useDomainRepo } from '../hooks/useDomainRepo';
import { getDomainFromEmail } from '../utils/domainUtils';
import { useClientDataRepo } from '../hooks/useClientDataRepo';

const useStyles = makeStyles({
  checkboxContainerTop: {
    paddingTop: '8px',
    paddingBottom: '4px',
    paddingRight: '8px',
    paddingLeft: '8px',
  },
  checkboxContainerBottom: {
    paddingTop: '4px',
    paddingBottom: '8px',
    paddingRight: '8px',
    paddingLeft: '8px',
  },
  centeredButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldContainer: {
    padding: '8px',
  },
  filledInput: {
    fontSize: 16,
    borderRadius: 0,
    height: '55px',
  },
  inputLabel: {
    marginTop: '14px',
  },
  textLoadingSelect: {
    minHeight: '50px',
  },
});

interface Props {
  data: SiteDetail | undefined;
  vendorPropChange: Function;
  isIdeaRoomUser: boolean;
  selectedGroupId: string;
}

export const SitesEmailAndContact: React.FC<Props> = ({
  vendorPropChange,
  isIdeaRoomUser = false,
  selectedGroupId,
  data = {},
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { clientEmailDomain, isFetchingClientEmailDomain, refetchClientEmailDomain } = useDomainRepo();
  const { selectedTableData } = useClientDataRepo({ useSelectedTableData: true });

  const [emailDomain, setEmailDomain] = useState(
    data.quoteEmailFromAddressSame
      ? getDomainFromEmail(data.emailAddress || '')
      : getDomainFromEmail(data.quoteEmailFromAddress || ''),
  );

  const getEmailField = (
    name: SiteDetailDataFields,
    value: string | undefined,
    props?: TextFieldProps | undefined,
  ): JSX.Element => (
    // eslint-disable-next-line react/prop-types
    <FormControl fullWidth style={props?.error ? { marginBottom: '15px' } : undefined}>
      <TextField
        className={classes.filledInput}
        label={t((sitesFieldsMap[name] as SiteDetailField).i18nKey)}
        name={name}
        onChange={(e): void => {
          vendorPropChange(e.target.name, e.target.value);
        }}
        value={value || ''}
        variant="filled"
        disabled={disabledSiteDetailField(name, data, isIdeaRoomUser, selectedGroupId)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </FormControl>
  );

  const getCheckboxField = (name: SiteDetailDataFields, value: boolean, onChangeFunction?: Function): JSX.Element => (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          value={value}
          checked={value}
          onChange={(e: any): void => {
            vendorPropChange(e.target.name, e.target.checked);
            onChangeFunction?.();
          }}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          disabled={disabledSiteDetailField(name, data, isIdeaRoomUser, selectedGroupId)}
        />
      }
      label={t((sitesFieldsMap[name] as SiteDetailField).i18nKey)}
    />
  );

  const getEmailSelectField = (
    name: SiteDetailDataFields,
    value: string | undefined,
    defaultValue: string,
    selections: { key: string; label: string }[],
  ): JSX.Element => (
    <FormControl fullWidth>
      <SiteInputLabel
        shrink
        className={classes.inputLabel}
        htmlFor={`${name}-select`}
        i18nKey={(sitesFieldsMap[name] as SiteDetailField).i18nKey}
        ideaRoomOnly={ideaRoomOnlySiteDetailField(name, data, isIdeaRoomUser, selectedGroupId)}
      />
      <LoadingSelect
        disabled={disabledSiteDetailField(name, data, isIdeaRoomUser, selectedGroupId)}
        alerttext={
          disabledSiteDetailField(name, data, isIdeaRoomUser, selectedGroupId)
            ? t(I18nKeys.SitesProSubscriptionAlert)
            : undefined
        }
        value={value || defaultValue}
        onChange={(e: any): void => vendorPropChange(e.target.name, e.target.value)}
        inputProps={{
          displayEmpty: false,
          name,
          id: `${name}-select`,
        }}
        className={classes.textLoadingSelect}
        variant="filled"
        loading={false}
      >
        {selections.map((option: any) => (
          <MenuItem key={option.key} value={option.key}>
            {t(option.label)}
          </MenuItem>
        ))}
      </LoadingSelect>
    </FormControl>
  );

  const { status } = clientEmailDomain;
  const verified = status === DomainStatus.Verified || status === DomainStatus.VerifiedIdeaRoomDomain;

  const openEmailDomainVerificationDialog = (): void => {
    dispatch(openDialog({ dialog: Dialogs.EmailDomain, options: { emailDomain: emailDomain.domain } }));
  };

  const getEmailDomainInputProps = (): TextFieldProps => {
    let error = false;
    let badgeIcon;
    let badgeTooltipText: string;
    let helperText: string | undefined;
    if (isFetchingClientEmailDomain) {
      badgeTooltipText = t(I18nKeys.SitesEmailDomainIconLoadingHoverText, { domain: emailDomain.domain });
      badgeIcon = <img src={domainLoading} alt="loading" />;
    } else {
      badgeIcon = <GppMaybe color="error" />;
      helperText = t(I18nKeys.SitesEmailDomainNotVerified);

      const tooltipI18nContext = { domain: emailDomain.domain };
      switch (status) {
        case DomainStatus.Verified:
        case DomainStatus.VerifiedIdeaRoomDomain:
          helperText = undefined;
          badgeIcon = (
            <IconButton color="success" aria-label="verified" onClick={openEmailDomainVerificationDialog}>
              <GppGood />
            </IconButton>
          );
          badgeTooltipText = t(I18nKeys.SitesEmailDomainIconVerifiedHoverText, tooltipI18nContext);
          break;
        case DomainStatus.Unverifiable:
          error = true;
          badgeTooltipText = t(I18nKeys.SitesEmailDomainIconUnverifiableHoverText, tooltipI18nContext);
          break;
        case DomainStatus.UnverifiableCustomSmtpHost:
          badgeIcon = <GppMaybe color="warning" />;
          badgeTooltipText = t(I18nKeys.SitesEmailDomainIconUnverifiableCustomSmtpHostHoverText, tooltipI18nContext);
          helperText = undefined;
          break;
        case DomainStatus.Unverified:
        case DomainStatus.DoesNotExist:
        default:
          error = true;
          badgeTooltipText = t(I18nKeys.SitesEmailDomainIconUnverifiedHoverText, tooltipI18nContext);
          break;
      }

      if (emailDomain.error) {
        error = true;
        helperText = emailDomain.error;
      }
    }

    return {
      error,
      helperText,
      slotProps: {
        input: {
          endAdornment: (
            <Tooltip title={badgeTooltipText}>
              <InputAdornment position="end">{badgeIcon}</InputAdornment>
            </Tooltip>
          ),
        },
      },
    };
  };

  const emailDomainInputProps = getEmailDomainInputProps();

  const verifyEmailDomainButton =
    // Dont show verify button in states where the domain should not be verified.
    [DomainStatus.VerifiedIdeaRoomDomain, DomainStatus.Unverifiable, DomainStatus.UnverifiableCustomSmtpHost].includes(
      status as DomainStatus,
    ) ? null : (
      <Button variant="contained" color="error" onClick={openEmailDomainVerificationDialog}>
        {t(I18nKeys.SitesEmailDomainManagementButton)}
      </Button>
    );

  const onChangeQuoteEmailFromAddressSame = (): void => {
    refetchClientEmailDomain();
  };

  useEffect(() => {
    if (selectedTableData && selectedTableData.length > 0) {
      const { emailAddress, quoteEmailFromAddress, quoteEmailFromAddressSame } = selectedTableData[0] || {};

      const quoteAddress = quoteEmailFromAddressSame ? emailAddress : quoteEmailFromAddress;
      const quoteEmailFromAddressDomain = getDomainFromEmail(quoteAddress as string);
      setEmailDomain(quoteEmailFromAddressDomain);
      if (!quoteEmailFromAddressDomain.error) {
        refetchClientEmailDomain();
      }
    }
  }, [selectedTableData]);

  return (
    <Grid container>
      <Grid container size={{ xs: 12 }}>
        <Grid size={{ xs: 12, sm: 6 }} className={classes.fieldContainer}>
          {getEmailField(
            SiteDetailDataFields.EmailAddress,
            data.emailAddress,
            data.quoteEmailFromAddressSame ? emailDomainInputProps : undefined,
          )}
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }} className={`${classes.fieldContainer} ${classes.centeredButtonContainer}`}>
          {!!data.quoteEmailFromAddressSame &&
            !isFetchingClientEmailDomain &&
            !verified &&
            !emailDomain.error &&
            verifyEmailDomainButton}
        </Grid>
      </Grid>
      <Grid container size={{ xs: 12 }}>
        <Grid size={{ xs: 12, sm: 6 }} className={classes.checkboxContainerTop}>
          <Box>
            {getCheckboxField(
              SiteDetailDataFields.QuoteEmailFromAddressSame,
              !!data.quoteEmailFromAddressSame,
              onChangeQuoteEmailFromAddressSame,
            )}
            {!data.quoteEmailFromAddressSame &&
              getEmailField(
                SiteDetailDataFields.QuoteEmailFromAddress,
                data.quoteEmailFromAddress,
                !data.quoteEmailFromAddressSame ? emailDomainInputProps : undefined,
              )}
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }} className={`${classes.fieldContainer} ${classes.centeredButtonContainer}`}>
          {!data.quoteEmailFromAddressSame &&
            !isFetchingClientEmailDomain &&
            !verified &&
            !emailDomain.error &&
            verifyEmailDomainButton}
        </Grid>
      </Grid>
      <Grid container size={{ xs: 12 }}>
        <Grid size={{ xs: 12, sm: 6 }} className={classes.checkboxContainerBottom}>
          <Box>
            {getCheckboxField(SiteDetailDataFields.QuoteEmailCopyAddressSame, !!data.quoteEmailCopyAddressSame)}
            {!(data.quoteEmailCopyAddressSame || false) &&
              getEmailField(SiteDetailDataFields.QuoteEmailCopyAddress, data.quoteEmailCopyAddress)}
          </Box>
        </Grid>
      </Grid>
      <Grid container size={{ xs: 12 }}>
        {[
          SiteDetailDataFields.CheckoutEmailFormat,
          SiteDetailDataFields.QuoteEmailFormat,
          SiteDetailDataFields.SaveEmailFormat,
          SiteDetailDataFields.PrintEmailFormat,
        ]
          .filter((key) => !hideSiteDetailField(key as SiteDetailDataFields, data, isIdeaRoomUser, selectedGroupId))
          .map((key) => (
            <Grid size={{ xs: 12, sm: 6 }} className={classes.fieldContainer} key={key}>
              {getEmailSelectField(key, (data as any)[key], EmailFormat.Summary, emailFormatOptions)}
            </Grid>
          ))}
      </Grid>
      <Grid container size={{ xs: 12 }}>
        <Grid size={{ xs: 12, sm: 6 }} className={classes.fieldContainer}>
          {getEmailSelectField(
            SiteDetailDataFields.SendEmailsTo,
            data.sendEmailsTo,
            SendEmailTo.Default,
            sendEmailsToOptions,
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
