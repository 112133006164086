import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormHelperText,
  Grid2 as Grid,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { Field, FieldArray, Form, InjectedFormProps, reduxForm, reset, submit } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { DealerFormFields } from '../constants/FormFields';
import { Forms } from '../constants/Forms';
import { setDealerDialog } from '../ducks/dealers';
import { closeDialog } from '../ducks/dialogSlice';
import { AppState } from '../types/AppState';
import { DepositAmount } from '../types/DepositAmount';
import { isCarportView } from '../utils/clientIdUtils';
import { required } from '../utils/reduxFormUtils';
import { DepositAmounts } from './DepositAmounts';
import { Dialog } from './Dialog';
import { LoadingButton } from './LoadingButton';
import { NewWindowLink } from './NewWindowLink';
import { CheckboxField } from './redux-form/CheckboxField';
import { InputField } from './redux-form/InputField';
import { isIdeaRoomUser } from '../utils/userUtils';
import { unknownUser, User } from '../types/User';
import { unknownGroup } from '../constants/Group';
import { I18nKeys } from '../constants/I18nKeys';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  field: {
    width: '100%',
  },
  error: {
    color: 'red',
    marginTop: '10px',
  },
  imageOrMessageGrid: {
    height: theme.spacing(6),
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    height: theme.spacing(6),
    padding: theme.spacing(1),
    background: theme.palette.primary.main,
    alignSelf: 'center',
  },
  linkGrid: {
    display: 'flex',
  },
  checkboxLabel: {
    paddingLeft: theme.spacing(3),
  },
  hidden: {
    display: 'none',
  },
}));

interface FormData {
  [DealerFormFields.ClientId]: string;
  [DealerFormFields.DealerName]: string;
  [DealerFormFields.City]: string;
  [DealerFormFields.State]: string;
  [DealerFormFields.ZipCode]: string;
  [DealerFormFields.Key]: string;
  [DealerFormFields.Id]: number;
  [DealerFormFields.PhoneNumber]: string;
  [DealerFormFields.CustomLogoUrl]: string;
  [DealerFormFields.DealerUrl]: string;
  [DealerFormFields.HomeLinkUrl]: string;
  [DealerFormFields.EmailAddress]: string;
  [DealerFormFields.QuoteEmailReplyToSame]: boolean;
  [DealerFormFields.QuoteEmailReplyToAddress]: string;
  [DealerFormFields.QuoteEmailCopySame]: boolean;
  [DealerFormFields.QuoteEmailCopyAddress]: string;
  [DealerFormFields.DepositAmounts]: DepositAmount[];
  [DealerFormFields.Integration]: string;
  [DealerFormFields.ContactBarHtml]: string;
  [DealerFormFields.EmailLogoUrl]: string;
  [DealerFormFields.IntegrationsKeySame]: boolean;
  [DealerFormFields.IntegrationsKey]: string;
}

interface DispatchProps {
  addOrUpdateDealer(): void;
  closeDealerDialog(): void;
  onSubmit(data: FormData): Promise<void>;
}

interface StateProps {
  copySame: boolean;
  integrationsKeySame: boolean;
  disabled: boolean;
  initialEmailLogoUrl?: string;
  initialDealerUrl: string;
  initialHomeLinkUrl: string;
  initialLogoUrl?: string;
  replyToSame: boolean;
  user: User;
  configuratorLink: string;
}

type Props = StateProps & DispatchProps & InjectedFormProps<FormData>;

const DealerDialogComponent: React.FC<Props> = ({
  addOrUpdateDealer,
  closeDealerDialog,
  copySame,
  integrationsKeySame,
  error,
  handleSubmit,
  initialEmailLogoUrl,
  initialDealerUrl,
  initialHomeLinkUrl,
  initialLogoUrl,
  initialValues,
  onSubmit,
  disabled,
  replyToSame,
  submitting,
  user,
  configuratorLink,
  change,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [customLogoLoaded, setCustomLogoLoaded] = React.useState<boolean>(false);
  const [customLogoUrl, setCustomLogoUrl] = React.useState(initialLogoUrl);
  const [replyToSameChecked, setReplyToSameChecked] = React.useState<boolean>(replyToSame);
  const [copySameChecked, setCopySameChecked] = React.useState<boolean>(copySame);
  const [integrationsSameChecked, setIntegrationsSameChecked] = React.useState<boolean>(integrationsKeySame);
  const [advancedPanelExpanded, setAdvancedPanelExpanded] = React.useState<boolean>(false);
  const [dealerUrl, setDealerUrl] = React.useState(initialDealerUrl);
  const [homeLinkUrl, setHomeLinkUrl] = React.useState(initialHomeLinkUrl);
  const [emailLogoLoaded, setEmailLogoLoaded] = React.useState<boolean>(false);
  const [emailLogoUrl, setEmailLogoUrl] = React.useState(initialEmailLogoUrl);

  React.useEffect(() => setReplyToSameChecked(replyToSame), [replyToSame]);
  React.useEffect(() => setReplyToSameChecked(copySame), [copySame]);
  React.useEffect(() => setIntegrationsSameChecked(integrationsKeySame), [integrationsKeySame]);
  React.useEffect(() => setCustomLogoUrl(initialLogoUrl), [initialLogoUrl]);
  React.useEffect(() => setEmailLogoUrl(initialEmailLogoUrl), [initialEmailLogoUrl]);
  React.useEffect(() => setDealerUrl(initialDealerUrl), [initialDealerUrl]);
  React.useEffect(() => setHomeLinkUrl(initialHomeLinkUrl), [initialHomeLinkUrl]);

  const showCarportViewFields = isCarportView(initialValues[DealerFormFields.ClientId] || '');

  const handleDealerIdChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const url = `${configuratorLink}${configuratorLink.includes('?') ? '&' : '?'}dealer=${event.target.value}`;
    change(DealerFormFields.DealerUrl, url);
    setDealerUrl(url);
    // If the home link URL hasn't been changed, update to new dealer ID
    if (!initialHomeLinkUrl || initialHomeLinkUrl === initialDealerUrl) {
      change(DealerFormFields.HomeLinkUrl, url);
      setHomeLinkUrl(url);
    }
  };

  const handleCustomLogoUrlChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCustomLogoLoaded(false);
    setCustomLogoUrl(event.target.value);
  };

  const handleEmailLogoUrlChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailLogoLoaded(false);
    setEmailLogoUrl(event.target.value);
  };

  const handleReplyToSameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setReplyToSameChecked(event.target.checked);
  };

  const handleCopySameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setCopySameChecked(event.target.checked);
  };

  const handleIntegrationsSameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIntegrationsSameChecked(event.target.checked);
  };

  const handleAdvancedPanelChange = (event: React.ChangeEvent<any>, expanded: boolean): void => {
    setAdvancedPanelExpanded(expanded);
  };

  const handleDealerUrlChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDealerUrl(event.target.value);
  };

  const handleHomeLinkUrlChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setHomeLinkUrl(event.target.value);
  };

  const closeAndResetDialog = (): void => {
    closeDealerDialog();
    setCustomLogoUrl(undefined);
    setCustomLogoLoaded(false);
    setReplyToSameChecked(true);
    setCopySameChecked(true);
    setIntegrationsSameChecked(true);
    setAdvancedPanelExpanded(false);
  };

  return (
    <Dialog dialogKey={Dialogs.Dealer} onClosed={closeAndResetDialog}>
      <DialogTitle>{t(I18nKeys.DealerDialogTitle)}</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column">
            <Grid container spacing={2} className={classes.row}>
              <Grid size={{ xs: 12 }}>
                <Field
                  className={classes.field}
                  autoComplete="off"
                  autoFocus
                  component={InputField}
                  props={{ disabled }}
                  required
                  label={t(I18nKeys.DealerDialogDealerNameField)}
                  name={DealerFormFields.DealerName}
                  validate={required}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.row}>
              <Grid size={{ xs: 12 }}>
                <Field
                  className={classes.field}
                  autoComplete="off"
                  component={InputField}
                  props={{ disabled }}
                  required
                  label={t(I18nKeys.DealerDialogDealerIdField)}
                  validate={required}
                  name={DealerFormFields.Key}
                  onChange={handleDealerIdChanged}
                />
                <FormHelperText>{t(I18nKeys.DealerDialogDealerIdFieldHelpText)}</FormHelperText>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.row}>
              <Grid size={{ xs: 12 }} className={classes.linkGrid}>
                <Field
                  className={classes.field}
                  autoComplete="off"
                  component={InputField}
                  props={{ disabled: true }}
                  label={t(I18nKeys.DealerDialogDealerSiteLinkField)}
                  name={DealerFormFields.DealerUrl}
                  onChange={handleDealerUrlChange}
                />
                <NewWindowLink link={dealerUrl} />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.row}>
              <Grid size={{ sm: 7, xs: 12 }}>
                <Field
                  className={classes.field}
                  autoComplete="off"
                  component={InputField}
                  props={{ disabled }}
                  label={t(I18nKeys.DealerDialogDealerCityField)}
                  name={DealerFormFields.City}
                />
              </Grid>
              <Grid size={{ sm: 2, xs: 6 }}>
                <Field
                  className={classes.field}
                  autoComplete="off"
                  component={InputField}
                  props={{ disabled }}
                  label={t(I18nKeys.DealerDialogDealerStateField)}
                  name={DealerFormFields.State}
                />
              </Grid>
              <Grid size={{ sm: 3, xs: 6 }}>
                <Field
                  className={classes.field}
                  autoComplete="off"
                  component={InputField}
                  props={{ disabled }}
                  label={t(I18nKeys.DealerDialogDealerZipField)}
                  name={DealerFormFields.ZipCode}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.row}>
              <Grid size={{ xs: 12 }}>
                <Field
                  className={classes.field}
                  autoComplete="off"
                  component={InputField}
                  props={{ disabled }}
                  label={t(I18nKeys.FieldPhone)}
                  name={DealerFormFields.PhoneNumber}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.row}>
              <Grid size={{ xs: 12 }}>
                <Field
                  className={classes.field}
                  autoComplete="off"
                  component={InputField}
                  props={{ disabled }}
                  label={t(I18nKeys.DealerDialogDealerEmailField)}
                  name={DealerFormFields.EmailAddress}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Box color={disabled ? 'text.disabled' : undefined}>
                  <Field
                    id={DealerFormFields.QuoteEmailReplyToSame}
                    name={DealerFormFields.QuoteEmailReplyToSame}
                    component={CheckboxField}
                    props={{ disabled }}
                    onChange={handleReplyToSameChange}
                  />
                  <Typography component="span" className={classes.checkboxLabel}>
                    <>{t(I18nKeys.DealerDialogEmailReplyToSameCheckbox)}</>
                  </Typography>
                </Box>
                {!replyToSameChecked && (
                  <Field
                    className={classes.field}
                    autoComplete="off"
                    component={InputField}
                    props={{ disabled }}
                    label={t(I18nKeys.DealerDialogEmailReplyToSameField)}
                    name={DealerFormFields.QuoteEmailReplyToAddress}
                  />
                )}
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Box color={disabled ? 'text.disabled' : undefined}>
                  <Field
                    id={DealerFormFields.QuoteEmailCopySame}
                    name={DealerFormFields.QuoteEmailCopySame}
                    component={CheckboxField}
                    props={{ disabled }}
                    onChange={handleCopySameChange}
                  />
                  <Typography component="span" className={classes.checkboxLabel}>
                    <>{t(I18nKeys.DealerDialogEmailCopySameCheckbox)}</>
                  </Typography>
                </Box>
                {!copySameChecked && (
                  <Field
                    className={classes.field}
                    autoComplete="off"
                    component={InputField}
                    props={{ disabled }}
                    label={t(I18nKeys.DealerDialogEmailCopySameField)}
                    name={DealerFormFields.QuoteEmailCopyAddress}
                  />
                )}
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Box color={disabled ? 'text.disabled' : undefined}>
                  <Field
                    id={DealerFormFields.IntegrationsKeySame}
                    name={DealerFormFields.IntegrationsKeySame}
                    component={CheckboxField}
                    props={{ disabled }}
                    onChange={handleIntegrationsSameChange}
                  />
                  <Typography component="span" className={classes.checkboxLabel}>
                    <>{t(I18nKeys.DealerDialogCustomDealerIdCheckbox)}</>
                  </Typography>
                </Box>
                {!integrationsSameChecked && (
                  <Field
                    className={classes.field}
                    autoComplete="off"
                    component={InputField}
                    props={{ disabled }}
                    label={t(I18nKeys.DealerDialogCustomDealerIdField)}
                    name={DealerFormFields.IntegrationsKey}
                  />
                )}
              </Grid>
            </Grid>
            {showCarportViewFields && (
              <Grid container spacing={2} className={classes.row}>
                <Grid size={{ xs: 12 }}>
                  <FieldArray name={DealerFormFields.DepositAmounts} component={DepositAmounts} props={{ disabled }} />
                </Grid>
              </Grid>
            )}
            {isIdeaRoomUser(user) && (
              <Accordion expanded={advancedPanelExpanded} onChange={handleAdvancedPanelChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t(I18nKeys.DealerDialogAdvancedSection)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} className={classes.row}>
                    <Grid size={{ sm: 7, xs: 12 }}>
                      <Field
                        className={classes.field}
                        autoComplete="off"
                        component={InputField}
                        props={{ disabled }}
                        label={t(I18nKeys.DealerDialogCustomLogoUrlField)}
                        name={DealerFormFields.CustomLogoUrl}
                        onChange={handleCustomLogoUrlChanged}
                      />
                    </Grid>
                    <Grid size={{ sm: 5, xs: 12 }} className={classes.imageOrMessageGrid}>
                      <img
                        style={{
                          visibility: customLogoLoaded && !!customLogoUrl ? 'initial' : 'hidden',
                          position: customLogoLoaded && !!customLogoUrl ? 'initial' : 'absolute',
                        }}
                        src={customLogoUrl}
                        alt="Custom Logo"
                        className={classes.image}
                        onLoad={(): void => setCustomLogoLoaded(true)}
                        onError={(): void => setCustomLogoLoaded(false)}
                      />
                      {(!customLogoLoaded || !customLogoUrl) && (
                        <Box className={classes.error}>{t(I18nKeys.DealerDialogCustomLogoError)}</Box>
                      )}
                    </Grid>
                    <Grid size={{ sm: 7, xs: 12 }}>
                      <Field
                        className={classes.field}
                        autoComplete="off"
                        component={InputField}
                        props={{ disabled }}
                        label={t(I18nKeys.DealerDialogEmailLogoUrlField)}
                        name={DealerFormFields.EmailLogoUrl}
                        onChange={handleEmailLogoUrlChanged}
                      />
                    </Grid>
                    <Grid size={{ sm: 5, xs: 12 }} className={classes.imageOrMessageGrid}>
                      <img
                        style={{
                          visibility: emailLogoLoaded && !!emailLogoUrl ? 'initial' : 'hidden',
                          position: emailLogoLoaded && !!emailLogoUrl ? 'initial' : 'absolute',
                        }}
                        src={emailLogoUrl}
                        alt="Email Logo"
                        className={classes.image}
                        onLoad={(): void => setEmailLogoLoaded(true)}
                        onError={(): void => setEmailLogoLoaded(false)}
                      />
                      {(!emailLogoLoaded || !emailLogoUrl) && (
                        <Box className={classes.error}>{t(I18nKeys.DealerDialogEmailLogoError)}</Box>
                      )}
                    </Grid>
                    <Grid size={{ xs: 12 }} className={classes.linkGrid}>
                      <Field
                        className={classes.field}
                        autoComplete="off"
                        component={InputField}
                        label={t(I18nKeys.DealerDialogHomeLinkField)}
                        name={DealerFormFields.HomeLinkUrl}
                        onChange={handleHomeLinkUrlChange}
                      />
                      <NewWindowLink link={homeLinkUrl} />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <Field
                        className={classes.field}
                        autoComplete="off"
                        component={InputField}
                        props={{ disabled }}
                        label={t(I18nKeys.DealerDialogContactBarHTMLField)}
                        name={DealerFormFields.ContactBarHtml}
                      />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <Field
                        className={classes.field}
                        autoComplete="off"
                        component={InputField}
                        props={{ disabled }}
                        label={t(I18nKeys.DealerDialogIntegrationField)}
                        name={DealerFormFields.Integration}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
            <Grid container spacing={2} className={classes.row} />
          </Grid>
        </Form>
        {!!error && <Typography className={classes.error}>{error}</Typography>}
      </DialogContent>
      <DialogActions>
        {disabled && (
          <LoadingButton loading={submitting} onClick={closeAndResetDialog} color="primary">
            {t(I18nKeys.DialogCloseButton)}
          </LoadingButton>
        )}
        {!disabled && (
          <>
            <LoadingButton loading={submitting} onClick={closeAndResetDialog} color="primary">
              {t(I18nKeys.DialogCloseButton)}
            </LoadingButton>
            <LoadingButton loading={submitting} onClick={addOrUpdateDealer} color="primary">
              {t(I18nKeys.DialogSaveButton)}
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState): Partial<InjectedFormProps<FormData>> & StateProps => {
  const {
    dealers: { dialogDealer, dialogClientId, logoUrl },
    currentUser: { user = unknownUser, group: { configurators } = unknownGroup },
  } = state;

  const depositAmounts: DepositAmount[] = [];
  if (dialogDealer) {
    if (dialogDealer.depositPrice instanceof Array && dialogDealer.depositPercent instanceof Array) {
      depositAmounts.push(
        ...dialogDealer.depositPrice.reduce<DepositAmount[]>((res, cur, index): DepositAmount[] => {
          res.push({ price: cur, percent: dialogDealer.depositPercent[index] });
          return res;
        }, []),
      );
    } else if (typeof dialogDealer.depositPrice === 'string' && typeof dialogDealer.depositPercent === 'string') {
      depositAmounts.push({ price: dialogDealer.depositPrice, percent: dialogDealer.depositPercent });
    }
  }
  // depositPrice and depositPercent are historically store descending. Reverse the depositAmounts objects so that
  // the form can use them in ascending order.
  depositAmounts.reverse();

  let configuratorLink = '';
  if (configurators) {
    const selectedConfigurator = configurators.find((config) => dialogClientId === `${config.key}-${config.vendor}`);
    configuratorLink = selectedConfigurator ? selectedConfigurator.url || '' : '';
  }

  return {
    initialValues: dialogDealer
      ? {
          [DealerFormFields.ClientId]: dialogClientId,
          [DealerFormFields.DealerName]: dialogDealer.name,
          [DealerFormFields.City]: dialogDealer.city,
          [DealerFormFields.State]: dialogDealer.state,
          [DealerFormFields.ZipCode]: dialogDealer.zip,
          [DealerFormFields.Key]: dialogDealer.key,
          [DealerFormFields.Id]: Number(dialogDealer.id) || undefined,
          [DealerFormFields.PhoneNumber]: dialogDealer.phoneNumber,
          [DealerFormFields.CustomLogoUrl]: dialogDealer.logoUrl,
          [DealerFormFields.DealerUrl]: dialogDealer.dealerURL,
          [DealerFormFields.HomeLinkUrl]: dialogDealer.homeLinkUrl,
          [DealerFormFields.EmailAddress]: dialogDealer.emailAddress,
          [DealerFormFields.QuoteEmailReplyToSame]: dialogDealer.emailAddress === dialogDealer.quoteEmailReplyToAddress,
          [DealerFormFields.QuoteEmailReplyToAddress]: dialogDealer.quoteEmailReplyToAddress,
          [DealerFormFields.QuoteEmailCopySame]: dialogDealer.emailAddress === dialogDealer.quoteEmailCopyAddress,
          [DealerFormFields.QuoteEmailCopyAddress]: dialogDealer.quoteEmailCopyAddress,
          [DealerFormFields.DepositAmounts]: depositAmounts,
          [DealerFormFields.Integration]: JSON.stringify(dialogDealer.integration),
          [DealerFormFields.ContactBarHtml]: dialogDealer.contactBarCustomHtml,
          [DealerFormFields.EmailLogoUrl]: dialogDealer.emailLogoUrl,
          [DealerFormFields.IntegrationsKeySame]:
            !dialogDealer.integrationsKey || dialogDealer.key === dialogDealer.integrationsKey,
          [DealerFormFields.IntegrationsKey]:
            !dialogDealer.integrationsKey || dialogDealer.key === dialogDealer.integrationsKey
              ? ''
              : dialogDealer.integrationsKey,
        }
      : {
          [DealerFormFields.ClientId]: dialogClientId,
          [DealerFormFields.QuoteEmailReplyToSame]: true,
          [DealerFormFields.QuoteEmailCopySame]: true,
          [DealerFormFields.CustomLogoUrl]: logoUrl,
          [DealerFormFields.IntegrationsKeySame]: true,
        },
    initialEmailLogoUrl: dialogDealer ? dialogDealer.emailLogoUrl : '',
    initialDealerUrl: dialogDealer ? dialogDealer.dealerURL : '',
    initialHomeLinkUrl: dialogDealer ? dialogDealer.homeLinkUrl : '',
    initialLogoUrl: dialogDealer ? dialogDealer.logoUrl : logoUrl,
    replyToSame: dialogDealer ? dialogDealer.emailAddress === dialogDealer.quoteEmailReplyToAddress : true,
    copySame: dialogDealer ? dialogDealer.emailAddress === dialogDealer.quoteEmailCopyAddress : true,
    integrationsKeySame: dialogDealer
      ? !dialogDealer.integrationsKey || dialogDealer.key === dialogDealer.integrationsKey
      : true,
    disabled: false,
    user,
    configuratorLink,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  addOrUpdateDealer: (): void => {
    dispatch(submit(Forms.Dealer));
  },
  closeDealerDialog: (): void => {
    dispatch(reset(Forms.Dealer));
    dispatch(closeDialog());
    dispatch(setDealerDialog(undefined, undefined, undefined));
  },
  onSubmit: (values: FormData): Promise<void> =>
    new Promise((resolve, reject): void => {
      dispatch({
        type: `${Forms.Dealer}_SUBMIT`,
        values,
        resolve,
        reject,
      });
    }),
});

export const DealerDialog = compose<ComponentType>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormData>({ form: Forms.Dealer, enableReinitialize: true }),
)(DealerDialogComponent);
