import { CellRange, GridApi } from 'ag-grid-community';
import { AnyAction, Dispatch } from 'redux';
import { GridData, TableData } from '../types/DataGrid';
import { getCellRangeInfo, isColumnHiddenForClient, updateValues } from './clientDataUtils';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { CellMetadata, SearchResultPart, TableMetadata } from '../types/ClientData';
import { replaceValuesComplete, UpdateClientDataRow } from '../ducks/clientDataSlice';
import { compoundCaseToTitleCase } from './stringUtils';
import { SearchOptions, SearchType } from '../constants/ClientData';
import { getVendorFromClientId } from './clientIdUtils';

/**
 * Determines if the given value contains the search value
 *
 * @param {string} value
 * @param {string} searchValue
 * @param {{ [key in SearchOptions]?: boolean }} searchOptions
 * @returns {boolean} true if the value contains the search value
 */
export const matchExists = (
  value: string,
  searchValue: string,
  searchOptions: { [key in SearchOptions]?: boolean },
): boolean => {
  const { [SearchOptions.MatchCase]: matchCase = false } = searchOptions;
  const val = matchCase ? value : value.toLowerCase();
  const searchVal = matchCase ? searchValue : searchValue.toLowerCase();
  return !!value && !!searchValue && val.split(searchVal).length > 1;
};

/**
 * Splits the given value by the search value and returns an array of SearchResultParts
 *
 * @param {string} value
 * @param {string} searchValue
 * @param {{ [key in SearchOptions]?: boolean }} searchOptions
 *
 * @returns {SearchResultPart[]} an array of SearchResultParts
 */
export const splitByMatches = (
  value: string,
  searchValue: string,
  searchOptions: { [key in SearchOptions]?: boolean },
): SearchResultPart[] => {
  const { [SearchOptions.MatchCase]: matchCase = false } = searchOptions;
  if (!value) return [];
  if (!searchValue) return [{ text: value, highlight: false }];

  let index = 0;
  const regEscape = (v: string) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  return `${value}`
    .split(new RegExp(regEscape(searchValue), `${matchCase ? '' : 'i'}g`))
    .map((text, i, arr) => {
      const parts = [{ text, highlight: false }];
      index += text.length;
      if (i < arr.length - 1) {
        parts.push({ text: value.slice(index, index + searchValue.length), highlight: true });
        index += searchValue.length;
      }
      return parts;
    })
    .flat()
    .filter((part) => !!part.text);
};

/**
 * Check if the given row and column are within the current selection
 *
 * @param {string | number | null} rowId
 * @param {string} columnId
 * @param {GridApi | null} gridApi
 *
 * @returns {boolean} true if the row and column are within the current selection
 */
export const withinCurrentSelection = (
  rowId?: string | number | null,
  columnId?: string,
  gridApi?: GridApi | null,
): boolean => {
  const selectedRanges = gridApi?.getCellRanges() || [];

  // There are no selections, so ignore the current selection
  if (!selectedRanges.length) return true;
  if (!rowId || !columnId) return false;

  return selectedRanges.some((range: CellRange) => {
    const { startRowIndex: start, endRowIndex: end } = getCellRangeInfo([range]);
    const columnIds = (range.columns || []).map((column) => column.getColId());
    const rowIndex = gridApi?.getRowNode(rowId.toString())?.rowIndex;

    // Range or row index is invalid, so ignore range
    if ([start, end, rowIndex].some((val) => !val && val !== 0) || !columnIds.length) return false;

    return (rowIndex as number) >= start && (rowIndex as number) <= end && columnIds.includes(columnId);
  });
};

export const searchColumnMatches = (
  rowId: string,
  column: string,
  value: string | number | boolean | null,
  searchParams: {
    clientId?: string;
    searchValue: string;
    searchType: SearchType;
    searchOptions: { [key in SearchOptions]?: boolean };
    tableMetadata?: TableMetadata;
  },
  gridApi?: GridApi | null,
) => {
  const { clientId = '', searchValue, searchType, searchOptions } = searchParams;
  const { metadata: columnMetadata } = searchParams.tableMetadata || {};
  if (
    columnMetadata &&
    columnMetadata[column] &&
    (columnMetadata[column].searchable === false || isColumnHiddenForClient(clientId, columnMetadata[column]))
  )
    return false;
  return (
    matchExists(value?.toString() || '', searchValue, searchOptions) &&
    // If only searching the current selection, make sure the cell is within the current selection
    (searchType !== SearchType.CurrentSelection || withinCurrentSelection(rowId, column, gridApi))
  );
};

/**
 * Search the data for the given search value
 *
 * @param {GridData} data
 * @param {{ clientId: string; table: string; searchValue: string; searchType: SearchType,
 * searchOptions: { [key in SearchOptions]?: boolean }; tableMetadata: TableMetadata }} searchParams
 * @param {GridApi | null} gridApi
 *
 * @returns {{ [clientId: string]: GridData }} results
 */
export const searchCurrentTable = (
  data: TableData[],
  searchParams: {
    clientId: string;
    table: string;
    searchValue: string;
    searchType: SearchType;
    searchOptions: { [key in SearchOptions]?: boolean };
    tableMetadata?: TableMetadata;
  },
  gridApi?: GridApi | null,
): { [clientId: string]: GridData } => {
  const { clientId, table } = searchParams;

  const searchResults = data.reduce((results, row) => {
    // See if at least one cell in the row matches the search value
    if (
      Object.entries(row).some(([columnId, cellData]) =>
        searchColumnMatches(row[ClientDataFixedColumns.RowId], columnId, cellData, searchParams, gridApi),
      )
    ) {
      results.push(row);
    }
    return results;
  }, [] as TableData[]);

  return { [clientId]: { [table]: searchResults } };
};

export const searchResultsForColumnMatches = (
  columns: string[],
  table: string,
  searchParams: {
    clientId?: string;
    searchValue: string;
    searchType: SearchType;
    searchOptions: { [key in SearchOptions]?: boolean };
    tablesMetadata: TableMetadata[];
  },
  gridApi?: GridApi | null,
): string[] => {
  const { clientId = '', searchValue = '', searchType, searchOptions, tablesMetadata } = searchParams;
  const { metadata: columnsMetadata = {} } =
    tablesMetadata.find(({ formattedTableName }) => formattedTableName === table) || {};

  return columns.filter((column) => {
    const columnMetadata = columnsMetadata[column];

    // Column should be ignored
    if (columnMetadata && (columnMetadata.searchable === false || isColumnHiddenForClient(clientId, columnMetadata))) {
      return false;
    }

    const { columns: selectedColumns } = getCellRangeInfo(gridApi?.getCellRanges() || []);
    // No valid matches
    if (
      !matchExists(compoundCaseToTitleCase(column), searchValue, searchOptions) ||
      (searchType === SearchType.CurrentSelection && !selectedColumns.includes(column))
    ) {
      return false;
    }

    return true;
  });
};

/**
 * Create a list of unique IDs for the search results
 *
 * @param {GridData} data
 * @param {{ clientId: string; searchValue: string; searchType: SearchType }} searchParams
 * @param {GridApi | null} gridApi
 *
 * @returns {string[]} search result IDs (clientId:tableId:rowId:columnId)
 */
export const getSearchResultIds = (
  data: GridData,
  tablesColumns: { [table: string]: string[] },
  searchParams: {
    mainClientId?: string;
    clientId?: string;
    searchValue: string;
    searchType: SearchType;
    searchOptions: { [key in SearchOptions]?: boolean };
    tablesMetadata: TableMetadata[];
  },
  gridApi?: GridApi | null,
): string[] => {
  const { mainClientId = '', clientId = '', searchType, tablesMetadata } = searchParams;
  const searchResultsIds: string[] = [];
  const columns = gridApi?.getAllGridColumns().map((col) => col.getColId()) || [];

  const sortColumns = (a: string, b: string) => columns.findIndex((c) => c === a) - columns.findIndex((c) => c === b);

  Object.keys(tablesColumns).forEach((table) => {
    const tableResultIds: string[] = [];

    // Add results for data matches
    [...(data[table] || [])]
      .sort(({ order: orderA }, { order: orderB }) => (orderA as number) - (orderB as number))
      .forEach((row, i) => {
        Object.keys(row)
          .sort(sortColumns)
          .forEach((column) => {
            const rowId = row[ClientDataFixedColumns.RowId];
            if (
              searchColumnMatches(
                rowId,
                column,
                data[table][i][column],
                {
                  ...searchParams,
                  tableMetadata: tablesMetadata.find(({ formattedTableName }) => formattedTableName === table),
                },
                gridApi,
              )
            ) {
              tableResultIds.push(`${clientId}:${table}:${rowId}:${column}`);
            }
          });
      });

    // Only include column matches if searching current vendor or matches exist for the table
    if (
      ![SearchType.AllVendorsAllTables, SearchType.AllVendorsCurrentTable].includes(searchType) ||
      tableResultIds.length ||
      (mainClientId && clientId === mainClientId)
    )
      tableResultIds.unshift(
        ...searchResultsForColumnMatches(tablesColumns[table] || [], table, searchParams, gridApi)
          .sort(sortColumns)
          .map((column) => `${clientId}:${table}::${column}`),
      );

    searchResultsIds.push(...tableResultIds);
  });
  return searchResultsIds;
};

/**
 * Get the maximum height for the Find All section of the search popup based on viewport height and
 * the heights of all sibling elements
 *
 * @param {object} refs refs to the search root and Find All section
 * @returns {string} max height
 */
export const getMaxFindAllSectionHeight = (refs: {
  searchRootRef: React.RefObject<HTMLDivElement>;
  findAllSectionRef: React.RefObject<HTMLDivElement>;
}): string => {
  const { searchRootRef, findAllSectionRef } = refs;
  // Get heights of all sibling elements and padding
  const siblingHeights =
    Array.from(searchRootRef.current?.children || [])
      .filter((child) => child !== findAllSectionRef.current)
      .reduce((height, child) => height + child.clientHeight, 0) + 16;

  // Subtract top bar, table bar, status bar, sibling heights, and extra for margin
  return `calc(100vh - 64px - 48px - 45px - ${siblingHeights}px - 30px)`;
};

/**
 * Replace all instances of the search value with the replace value in the given cell IDs
 *
 * @param clientDataTableId The client data ID
 * @param idsToReplace Cell IDs to replace values in
 * @param searchValue The value to search for
 * @param replaceValue The value to replace the search value with
 * @param cellMetadata The cell metadata
 * @param dispatch The dispatch function
 * @param gridApi The grid API
 */
export const replaceValues = (
  clientDataTableId: string,
  replaceParams: {
    searchData: GridData;
    searchValue: string;
    replaceValue: string;
    searchOptions: { [key in SearchOptions]?: boolean };
    onSearchComplete: (results: { [clientId: string]: GridData }) => void;
    cellMetadata: CellMetadata[];
    selectedTable: string;
    dispatch: Dispatch<any>;
    gridApi?: GridApi;
  },
  idsToReplace: string[],
  replaceAll: boolean,
) => {
  const {
    searchData,
    searchValue,
    replaceValue,
    searchOptions = {},
    cellMetadata,
    selectedTable,
    dispatch,
    gridApi,
  } = replaceParams;

  if (idsToReplace.length && gridApi) {
    let gridUpdates: TableData[] = [];
    const updateActions: { table?: string; data: any; column: string; oldValue: any; newValue: any }[] = [];

    idsToReplace.forEach((id) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_clientId, table, rowId, colId] = id.split(':');

      if (!rowId) return;

      const rowData =
        (searchData[table] || []).find((row) => row[ClientDataFixedColumns.RowId] === rowId) || ({} as TableData);
      const oldValue = rowData[colId]?.toString();

      if (!oldValue) return;

      const updatedValue = splitByMatches(oldValue, searchValue, searchOptions)
        .map((part) => (part.highlight ? replaceValue : part.text))
        .join('');
      const newValue = typeof rowData[colId] === 'number' ? parseInt(updatedValue, 10) : updatedValue;

      // Only update the grid if the cell is in the selected table
      if (table === selectedTable) {
        gridUpdates = [
          ...gridUpdates.filter((row: TableData) => row[ClientDataFixedColumns.RowId] !== rowId),
          {
            ...rowData,
            ...(gridUpdates.find((row: TableData) => row[ClientDataFixedColumns.RowId] === rowId) || {}),
            [colId]: newValue,
          },
        ];
      }
      updateActions.push({ table, data: rowData, column: colId, oldValue, newValue });
    });

    if (updateActions.length) {
      const getUndoActions = (rowUpdates: UpdateClientDataRow[]): AnyAction[] => [
        replaceValuesComplete({ params: replaceParams, replacedIds: idsToReplace, rowUpdates, replaceAll }),
      ];
      // Update the client data and undo manager with the new values
      updateValues(clientDataTableId, updateActions, cellMetadata, dispatch, getUndoActions);
    } else {
      dispatch(replaceValuesComplete({ params: replaceParams, replacedIds: idsToReplace, rowUpdates: [], replaceAll }));
    }
  }
};

/**
 * Finds the client search data for the given client ID
 *
 * @param searchData all client search data
 * @param clientId the client id to get the search data for
 * @returns
 */
export const getClientSearchData = (searchData: { [clientId: string]: GridData }, clientId: string): GridData => {
  const clientSearchData = searchData[getVendorFromClientId(clientId)];
  return clientSearchData || {};
};
