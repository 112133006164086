import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { Dialogs } from '../constants/Dialogs';
import { closeDialog } from '../ducks/dialogSlice';
import { Dialog } from './Dialog';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { LoadingButton } from './LoadingButton';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { unknownUser } from '../types/User';
import { compoundCaseToTitleCase } from '../utils/stringUtils';
import { BRANCH_LABELS, CELL_METADATA_TABLE, ClientVersion } from '../constants/ClientData';
import { DataGrid } from './DataGrid';
import { defaultFixedColumn } from '../constants/ClientDataColumn';
import { useGetClientVersionsQuery, useRollbackDatasetMutation } from '../services/clientDataApi';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { setClientDataBranch } from '../ducks/clientDataSlice';
import { autosizeColumns } from '../utils/clientDataUtils';
import { UserPreference } from '../constants/User';
import { ClientDataRollbackDialogOptions } from '../types/DialogState';

const DISPLAYED_VERSIONS_LIMIT = 100;

const useStyles = makeStyles({
  dialogActions: { padding: '0px 8px 8px 8px' },
  firstButton: {
    marginRight: 'auto',
  },
  vendorSelect: {
    minWidth: '500px',
    marginBottom: '10px',
  },
  list: {
    paddingLeft: '25px',
    margin: '0',
  },
  listBox: {
    maxHeight: '500px',
    minWidth: '500px',
    overflow: 'auto',
    flexBasis: '100%',
  },
  gridContainer: {
    height: '200px',
    width: '800px',
  },
  versionColumnCell: {
    textAlign: 'center',
    backgroundColor: 'var(--ag-header-background-color)',
  },
  row: {
    '&.ag-row-selected': {
      '& > $versionColumnCell': {
        backgroundColor: 'transparent',
      },
    },
    '&.ag-row-hover': {
      '& > $versionColumnCell': {
        backgroundColor: 'transparent',
      },
    },
  },
});

const EMPTY_CLIENT_VERSION_LIST: ClientVersion[] = [];

export const ClientDataRollbackDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    clientDataBranch = ClientDataBranch.Main,
    clientDataType,
    clientId,
  } = useAppSelector((state) => state?.clientData);
  const { options } = useAppSelector((state) => state?.dialog);
  const { selectVersion } = (options || {}) as ClientDataRollbackDialogOptions;
  const {
    user = unknownUser,
    preferences: { [UserPreference.ClientDataPreferences]: clientDataPreferences = {} } = {},
  } = useAppSelector((state) => state?.currentUser);
  const openDialogKey = useAppSelector((state) => state.dialog.key);
  const { activeBranches } = useClientDataRepo({ useBranches: true });
  const { currentData: versions = EMPTY_CLIENT_VERSION_LIST, isFetching } = useGetClientVersionsQuery(
    { dataType: clientDataType, clientId, limit: DISPLAYED_VERSIONS_LIMIT },
    {
      skip: !clientId || !clientDataBranch || openDialogKey !== Dialogs.ClientDataRollback,
      refetchOnMountOrArgChange: true,
    },
  );
  const [rollbackDataset, { isLoading: isRollingBack }] = useRollbackDatasetMutation();
  const gridRef = useRef<AgGridReact>(null);
  const [selectedVersion, setSelectedVersion] = useState<number | undefined>();
  const [showBranchOpenError, setShowBranchOpenError] = useState(false);

  useEffect(() => {
    setSelectedVersion(undefined);
    if (selectVersion !== undefined) {
      const node = gridRef.current?.api?.getRowNode(`${selectVersion}`);
      if (node) {
        node.setSelected(true, true);
        gridRef.current?.api.ensureNodeVisible(node);
        setSelectedVersion(selectVersion);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versions, selectVersion, gridRef.current]);

  const openBranch =
    activeBranches.find((branch) => branch.branchType === ClientDataBranch.Unpublished) ||
    activeBranches.find((branch) => branch.branchType === ClientDataBranch.Hotfix);

  const { authors = [], changedTables = [] } = openBranch || {};

  const displayTablesAsNumber = changedTables.length > 3;
  const displayAuthorsAsNumber = authors.length > 3;
  const localizedAuthors = authors.map((authorName) =>
    authorName === user.name ? t(I18nKeys.ClientDataBranchToolTipSummaryYouAuthor) : authorName,
  );
  const tableLabels = changedTables
    .filter((table) => table !== CELL_METADATA_TABLE)
    .map((table) => compoundCaseToTitleCase(table));
  const metadataChanges = changedTables.includes(CELL_METADATA_TABLE);

  const gridData = useMemo(
    () =>
      versions.length > 0
        ? [
            ...versions.map((version) => ({
              ...version,
              [ClientDataFixedColumns.RowId]: `${version.version}`,
              date: new Date(version.date).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' }),
            })),
            ...(versions.length < DISPLAYED_VERSIONS_LIMIT
              ? [
                  {
                    [ClientDataFixedColumns.RowId]: '0',
                    version: 0,
                    author: '-',
                    date: '-',
                    message: 'Original dataset',
                  },
                ]
              : []),
          ]
        : [],
    [versions],
  );
  const latestVersion = versions.reduce((latest, current) => (current.version > latest ? current.version : latest), 0);

  const selectedTableColumns = useMemo(
    () => [
      {
        ...defaultFixedColumn,
        headerName: 'Version',
        field: 'version',
        colId: 'version',
        cellClass: classes.versionColumnCell,
        valueFormatter: (params) =>
          params.data.version === latestVersion ? `${latestVersion} (Actual)` : params.data.version,
        maxWidth: 85,
      },
      {
        ...defaultFixedColumn,
        headerName: 'Published By',
        field: 'author',
        colId: 'author',
      },
      {
        ...defaultFixedColumn,
        headerName: 'Published On',
        field: 'date',
        colId: 'date',
      },
      {
        ...defaultFixedColumn,
        headerName: 'Change Description',
        field: 'message',
        colId: 'message',
        editable: true,
        cellEditorPopup: true,
        cellEditor: 'agLargeTextCellEditor',
      },
    ],
    [classes, latestVersion],
  );

  return (
    <Dialog dialogKey={Dialogs.ClientDataRollback} maxWidth="md" disableClose={isRollingBack}>
      {!showBranchOpenError && <DialogTitle>{t(I18nKeys.ClientDataRollbackDialogTitle)}</DialogTitle>}
      <DialogContent>
        {openBranch && showBranchOpenError && (
          <Typography>
            <Trans
              i18nKey={I18nKeys.ClientDataRollbackDialogOpenBranchText as string}
              values={{
                branch: t(BRANCH_LABELS[openBranch.branchType]),
                authors: displayAuthorsAsNumber
                  ? t(I18nKeys.ClientDataBranchToolTipSummaryAuthorsMultiple, { count: authors.length })
                  : t(I18nKeys.ClientDataBranchToolTipSummaryAuthors, {
                      authors: localizedAuthors,
                      count: authors.length,
                    }),
                changedTables: displayTablesAsNumber
                  ? t(I18nKeys.ClientDataBranchToolTipSummaryChangedTablesMultiple, {
                      count: changedTables.length,
                    })
                  : t(I18nKeys.ClientDataBranchToolTipSummaryChangedTables, {
                      changedTables: tableLabels,
                      count: changedTables.length,
                    }),
                metadataChanges: metadataChanges ? t(I18nKeys.ClientDataBranchToolTipSummaryMetadataChanges) : '',
              }}
              components={{ bold: <strong /> }}
            />
          </Typography>
        )}
        {!showBranchOpenError && (
          <>
            <Typography gutterBottom>
              <Trans i18nKey={I18nKeys.ClientDataRollbackDialogText as string} components={{ bold: <strong /> }} />
            </Typography>
            <div className={classes.gridContainer}>
              <DataGrid
                readOnly
                gridRef={gridRef}
                data={gridData}
                isLoading={isFetching}
                getContextMenuItems={undefined}
                selectedTable="version"
                selectedTableColumns={selectedTableColumns}
                onFirstDataRendered={(params) => {
                  autosizeColumns(params.api, clientDataPreferences);
                }}
                rowSelection="singleRow"
                enableRowClickSelection
                defaultColDefinition={{}}
                enableRangeSelection={false}
                suppressCellFocus
                getRowClass={() => classes.row}
                onCellClicked={(event) => {
                  setSelectedVersion(event.data.version);
                }}
              />
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          disabled={isRollingBack}
          onClick={(): void => {
            if (showBranchOpenError) {
              setShowBranchOpenError(false);
            } else {
              dispatch(closeDialog());
            }
          }}
          color="primary"
        >
          {t(showBranchOpenError ? I18nKeys.DialogGotItButton : I18nKeys.DialogCancelButton)}
        </Button>
        {!showBranchOpenError && (
          <LoadingButton
            onClick={async () => {
              if (openBranch) {
                setShowBranchOpenError(true);
              } else if (selectedVersion !== undefined) {
                rollbackDataset({
                  clientId,
                  dataType: clientDataType,
                  version: selectedVersion,
                })
                  .unwrap()
                  .then(() => {
                    dispatch(setClientDataBranch(ClientDataBranch.Unpublished));
                    dispatch(closeDialog());
                  });
              }
            }}
            disabled={selectedVersion === undefined || selectedVersion === latestVersion}
            loading={isRollingBack}
            color="primary"
          >
            {t(I18nKeys.ClientDataRollbackDialogViewData)}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
