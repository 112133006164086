import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import React from 'react';
import { Box, Checkbox, Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { unknownUser } from '../types/User';
import { unknownGroup } from '../constants/Group';
import { getUsersAvailableColumns } from '../utils/viewerUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchVisibleColumns, setVisibleColumns } from '../ducks/viewerSlice';

export const ColumnFilterMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = React.useState<null | HTMLDivElement>(null);
  const anchorElRef = React.createRef<HTMLDivElement>();

  const {
    user = unknownUser,
    group = unknownGroup,
    availableDealers = [],
  } = useAppSelector((state) => state.currentUser);
  const availableColumns = getUsersAvailableColumns(user, group, availableDealers);

  const { visibleColumns } = useAppSelector((state) => state.viewer);

  const handleColumnVisibilityChange = (columns: string[]): void => {
    dispatch(setVisibleColumns(columns));
  };

  React.useEffect(() => {
    if (!visibleColumns || visibleColumns.length < 1) {
      dispatch(fetchVisibleColumns());
    }
    // eslint-disable-next-line
  }, []);

  const handleFilterMenuClick = (): void => setFilterMenuAnchorEl(anchorElRef.current);
  const handleFilterMenuClose = (): void => setFilterMenuAnchorEl(null);

  const handleFilterMenuItemClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const columns = visibleColumns.includes(event.target.value)
      ? visibleColumns.filter((vColumn) => vColumn !== event.target.value)
      : [...visibleColumns, event.target.value];
    handleColumnVisibilityChange(columns);
  };

  return (
    <Grid container direction="row">
      <Grid container justifyContent="flex-end" alignItems="center">
        <Typography sx={{ display: { xs: 'none', sm: 'block' } }} color="textSecondary" variant="body2">
          {t(I18nKeys.ColumnFilterMenuColumnsLabel)}
        </Typography>
        <IconButton aria-controls="filter-menu" aria-haspopup="true" onClick={handleFilterMenuClick} size="large">
          <ViewWeekIcon />
        </IconButton>
        <Menu
          id="filter-menu"
          anchorEl={filterMenuAnchorEl}
          open={Boolean(filterMenuAnchorEl)}
          onClose={handleFilterMenuClose}
        >
          {availableColumns.map((availableColumn) => (
            <Box key={availableColumn} pr={2}>
              <Checkbox
                key={availableColumn}
                checked={visibleColumns.includes(availableColumn)}
                onChange={handleFilterMenuItemClick}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                value={availableColumn}
              />
              <Typography component="span">{t(availableColumn)}</Typography>
            </Box>
          ))}
        </Menu>
      </Grid>
      <Grid>
        <div style={{ position: 'relative', bottom: '-20px' }} ref={anchorElRef} />
      </Grid>
    </Grid>
  );
};
