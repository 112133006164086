import { toast } from 'react-toastify';
import { all, call, put, takeLatest, select, take } from 'redux-saga/effects';
import { Column, ColumnState, GridApi, IRowNode } from 'ag-grid-community';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import {
  setSelectedTable,
  fetchSearchResults as fetchSearchResultsAction,
  UpdateClientDataRow,
  goToCellRangeComplete,
  setReplacedResultIds,
  getSearchResultIndex as getSearchResultIndexAction,
  getSearchResultIndexComplete,
  setSearchResultIndex,
  goToCellRange as goToCellRangeAction,
  fetchSearchResultsComplete,
  setSearchResultIds,
  replaceValues as replaceValuesAction,
  replaceValuesComplete as replaceValuesCompleteAction,
  setHighlightedCell,
} from '../ducks/clientDataSlice';
import { ClientDataState } from '../types/ClientDataState';
import { AppState } from '../types/AppState';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { i18n } from '../i18n';
import { I18nKeys } from '../constants/I18nKeys';
import { ClientDataCacheTagType, SearchType } from '../constants/ClientData';
import { GridData, TableData } from '../types/DataGrid';
import { searchCurrentTable, getSearchResultIds, searchColumnMatches } from '../utils/searchUtils';
import { clientDataApi, getClientDataCacheTag } from '../services/clientDataApi';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { TableMetadata } from '../types/ClientData';
import { openConfirmationDialog } from '../ducks/confirmation';
import { Dialogs } from '../constants/Dialogs';
import { openDialog } from '../ducks/dialogSlice';
import { clearTableFilterModelFromSessionStorage, getAllGridData, getAllGridRowNodes } from '../utils/clientDataUtils';
import { getVendorFromClientId, mapClientIdToProduct, mapClientKeyAndProductToClientId } from '../utils/clientIdUtils';
import { extractErrorProps } from '../utils/errorUtils';
import { CurrentUserState } from '../types/CurrentUserState';
import { unknownGroup } from '../constants/Group';

/**
 * Wait in intervals for the given condition to be true or until timeout is reached.
 * @param condition The condition to wait for
 * @param interval The interval to wait in milliseconds
 * @param timeout The timeout to wait in milliseconds
 * @returns True if the condition was met within the timeout, false otherwise
 */
const waitForCondition = async (condition: () => boolean, interval = 500, timeout = 10000): Promise<boolean> => {
  if (condition()) return true;
  if (timeout <= 0) return false;
  // eslint-disable-next-line no-promise-executor-return
  await new Promise((resolve) => setTimeout(resolve, interval));
  return waitForCondition(condition, interval, timeout - interval);
};

const defaultErrorMessage = 'Something went wrong. Please try again.';

interface GoToCellRangeAction {
  type: string;
  payload: {
    table: string;
    location: {
      startRowId?: string;
      endRowId?: string;
      columns: string[];
    };
    rootGridApi: GridApi;
    onComplete?: () => void;
  };
}

function* goToCellRange({ payload: { table, location, rootGridApi, onComplete } }: GoToCellRangeAction): Generator {
  try {
    const { startRowId, endRowId, columns } = location;
    const {
      selectedTable,
      clientId,
      clientDataType,
      clientDataBranch,
      search: { type: searchType = SearchType.AllTables } = {},
    } = (yield select(({ clientData }: AppState) => clientData)) as ClientDataState;
    const { group: { groupId } = unknownGroup } = (yield select(
      ({ currentUser }: AppState) => currentUser,
    )) as CurrentUserState;

    const selectEntireRow = columns.length === 1 && columns[0] === ClientDataFixedColumns.Index;
    const selectColumnStart = !startRowId && !endRowId && columns.length;

    if (!columns.length || (!selectColumnStart && (!startRowId || !endRowId))) throw new Error('Invalid cell range.');
    if (!clientDataBranch) throw new Error('No branch selected.');

    if (table !== selectedTable) {
      yield put(setSelectedTable(table));
      const dataFetch = (yield put(
        clientDataApi.endpoints.getClientDataTableData.initiate({
          dataType: clientDataType,
          clientId,
          groupId,
          table,
          branch: clientDataBranch,
        }) as any,
      )) as QueryActionCreatorResult<any>;
      dataFetch.unsubscribe();
      // Wait until the table data is fetched and the grid is updated
      yield dataFetch;
    }

    const rowNodeExists = yield waitForCondition(() =>
      !selectColumnStart && startRowId ? !!rootGridApi.getRowNode(startRowId) : !!rootGridApi.getDisplayedRowAtIndex(0),
    );
    if (!rowNodeExists) throw new Error('Grid failed to load new table data');

    const { data: { [ClientDataFixedColumns.RowId]: columnStartRowId = '' } = {} } =
      rootGridApi.getDisplayedRowAtIndex(0) || {};

    // Check if the cell is available to be focused
    const cellIsRendered = (): boolean => {
      const allRowNodes = getAllGridRowNodes(rootGridApi);
      const renderedCols = rootGridApi.getAllDisplayedVirtualColumns();
      // Filter row nodes to only those that are displayed. Find the row node with the given row ID.
      const rowIsRendered = allRowNodes
        .filter((row) => row.displayed)
        .some(
          (node: IRowNode) =>
            node.data[ClientDataFixedColumns.RowId].toString() ===
            (selectColumnStart ? columnStartRowId : startRowId).toString(),
        );
      const columnIsRendered = renderedCols.some((col: Column) => col.getColId() === columns[0]);
      return rowIsRendered && columnIsRendered;
    };

    // Check if the rows exist
    const [startRow, endRow] = [startRowId, endRowId].map((rowId) =>
      rootGridApi.getRowNode(selectColumnStart ? columnStartRowId : rowId),
    );
    if (!startRow || !endRow) {
      const cellNotFoundError = i18n.t(I18nKeys.GoToCellNotFound);
      yield call(toast.error, cellNotFoundError);
      throw new Error(cellNotFoundError);
    }

    // Ensure the start of the range is visible
    const [colId] = columns;

    // column must be visible
    const { hide: columnIsHidden } =
      (rootGridApi.getColumnState() as ColumnState[]).find(({ colId: columnId }) => columnId === colId) || {};
    if (!columnIsHidden) {
      const [startRowIndex, endRowIndex] = [startRow, endRow].map((row) => row?.rowIndex);
      const cellReady = yield waitForCondition(() => cellIsRendered());
      rootGridApi.ensureColumnVisible(colId, 'auto');
      if (startRowIndex) rootGridApi.ensureIndexVisible(startRowIndex);
      if (cellReady) {
        // Focus the range
        if ((startRowIndex || startRowIndex === 0) && (endRowIndex || endRowIndex === 0)) {
          if (startRowId === endRowId && !selectEntireRow && columns.length === 1) {
            if (searchType !== SearchType.CurrentSelection) {
              // Allowing adding to range to highlight but keep focus on input for keyboard
              // shortcut to cycle through results
              rootGridApi.clearCellSelection();
              rootGridApi.addCellRange({
                rowStartIndex: startRowIndex,
                rowEndIndex: startRowIndex,
                columns: [colId],
              });
            }
            // Only focus single cell if selecting one cell
            // This allows replace in current selection search to not lose the selection
            rootGridApi.setFocusedCell(startRowIndex, colId);
          } else {
            rootGridApi.clearCellSelection();
            rootGridApi.addCellRange({
              rowStartIndex: startRowIndex,
              rowEndIndex: endRowIndex,
              columns: (selectEntireRow ? rootGridApi?.getColumns() : columns) || [],
            });
          }
        }
      } else {
        yield put(
          openConfirmationDialog(
            [
              goToCellRangeAction({
                table,
                location,
                rootGridApi,
              }),
            ],
            [
              () => {
                clearTableFilterModelFromSessionStorage(table);
                rootGridApi.setFilterModel({});
              },
            ],
            i18n.t(I18nKeys.GoToCellHiddenConfirmTitle),
            i18n.t(I18nKeys.GoToCellHiddenConfirmMessage),
            i18n.t(I18nKeys.GoToCellHiddenConfirmButton),
          ),
        );
        yield put(openDialog({ dialog: Dialogs.Confirmation }));
      }
    } else {
      yield put(
        openConfirmationDialog(
          [
            goToCellRangeAction({
              table,
              location,
              rootGridApi,
            }),
          ],
          [
            () => {
              rootGridApi.setColumnsVisible([colId], true);
            },
          ],
          i18n.t(I18nKeys.GoToCellHiddenColumnConfirmTitle),
          i18n.t(I18nKeys.GoToCellHiddenColumnConfirmMessage),
          i18n.t(I18nKeys.GoToCellHiddenColumnConfirmButton),
        ),
      );
      yield put(openDialog({ dialog: Dialogs.Confirmation }));
    }
  } catch {
    // Must supress errors or the saga stops working
  } finally {
    yield put(goToCellRangeComplete());
    if (onComplete) onComplete();
  }
}

function* getSearchResultIndex({ payload: { increment, gridApi, onComplete } }: any): Generator {
  try {
    const {
      clientId,
      search: { result: { ids: resultIds = [], index: resultIndex } = {}, replace: { ids: replaceIds = [] } = {} } = {},
    } = (yield select(({ clientData }: AppState) => clientData)) as ClientDataState;

    let index = resultIndex;
    if (index === undefined) index = 0;
    else index += increment;

    // Check if all results have been replaced
    const replacedAllResults = !!replaceIds.length && resultIds.every((id) => replaceIds.includes(id));

    // Looping around if at the end or beginning of the list
    // Set at beginning if looking for next replace and all have been replaced
    if (index < 0) index = resultIds.length - 1;
    else if (index >= resultIds.length) {
      index = 0;
      if (replacedAllResults) yield put(setReplacedResultIds([]));
    }

    // Skip over replaced results if looking for next result that hasn't been replaced
    if (!replacedAllResults && replaceIds.includes(resultIds[index])) {
      yield put(getSearchResultIndexAction({ increment: increment + 1, gridApi }));
      return;
    }

    yield put(setSearchResultIndex(index));

    const [resultClientId, table, rowId, colId] = resultIds[index].split(':');

    if (clientId === resultClientId) {
      yield put(
        goToCellRangeAction({
          table,
          location: { startRowId: rowId, endRowId: rowId, columns: [colId] },
          rootGridApi: gridApi,
          onComplete,
        }),
      );
      yield put(setHighlightedCell({ table, rowId, colId }));
    }
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    console.error(`getSearchResultIndex: `, errorMessage);
  } finally {
    yield put(getSearchResultIndexComplete());
  }
}

function* replaceValues({ payload: { onReplace, gridApi, replaceAll } }: any): Generator {
  try {
    // Make sure the search result index is valid and selected
    yield put(getSearchResultIndexAction({ increment: 0, gridApi }));
    yield take(goToCellRangeComplete.type);

    const { search: { result: { ids: resultIds = [], index } = {} } = {} } = (yield select(
      ({ clientData }: AppState) => clientData,
    )) as ClientDataState;

    const columnHeaderMatch = (resultId: string) => !resultId.split(':')[2];

    let isColumnHeaderMatch = false;
    let resultIdsToReplace: string[] = [];
    if (replaceAll) {
      // Filter out IDs that are column matches
      resultIdsToReplace = resultIds.filter((resultId: string) => !columnHeaderMatch(resultId));
    } else {
      resultIdsToReplace = [resultIds[index as number]];
      isColumnHeaderMatch = columnHeaderMatch(resultIdsToReplace[0]);
    }

    // Replace values
    yield call(onReplace, resultIdsToReplace);

    if (replaceAll && resultIdsToReplace.length) {
      const message = i18n.t(I18nKeys.ClientDataSearchReplaceSuccess, { count: resultIdsToReplace.length });
      toast.success(message);
    } else if (isColumnHeaderMatch) {
      const message = i18n.t(I18nKeys.ClientDataSearchReplaceColumnHeaderWarning);
      toast.warn(message);
    }
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    console.error(`replaceValues: `, errorMessage);
  }
}

function* replaceValuesComplete({ payload: { params, rowUpdates, replacedIds, replaceAll } }: any): Generator {
  try {
    const {
      clientId,
      clientDataType,
      selectedTable,
      search: {
        value: searchValue = '',
        type: searchType = SearchType.AllTables,
        options: searchOptions = {},
        replace: { ids: replacedResultIds = [] } = {},
      } = {},
      clientDataBranch = ClientDataBranch.Main,
    } = (yield select(({ clientData }: AppState) => clientData)) as ClientDataState;

    let tablesColumns: { [table: string]: string[] } = {};
    const tablesColumnsFetch = (yield put(
      clientDataApi.endpoints.getClientDataTablesColumns.initiate({
        dataType: clientDataType,
        clientId,
      }) as any,
    )) as QueryActionCreatorResult<any>;
    tablesColumnsFetch.unsubscribe();
    ({ data: tablesColumns } = (yield tablesColumnsFetch) as { data: { [table: string]: string[] } });

    let tablesMetadata: TableMetadata[] = [];
    const isUpdatingMultipleTables = rowUpdates.some(({ table }: UpdateClientDataRow) => table !== selectedTable);
    if (isUpdatingMultipleTables) {
      /*
        If the replace includes a different table from the selectedTable we just fetch all the tables metadata
        as this is probably a search/replace on multiple tables
      */
      const tablesMetadataFetch = (yield put(
        clientDataApi.endpoints.getClientDataAllTableMetadata.initiate({
          dataType: clientDataType,
          clientId,
          branch: clientDataBranch,
        }) as any,
      )) as QueryActionCreatorResult<any>;
      tablesMetadataFetch.unsubscribe();
      ({ data: tablesMetadata } = (yield tablesMetadataFetch) as { data: TableMetadata[] });
    } else {
      /*
        Otherwise we can just fetch the metadata for the current table for performance reasons 
       */
      const tableMetadataFetch = (yield put(
        clientDataApi.endpoints.getClientDataTableMetadata.initiate({
          dataType: clientDataType,
          clientId,
          table: selectedTable,
          branch: clientDataBranch,
        }) as any,
      )) as QueryActionCreatorResult<any>;
      tableMetadataFetch.unsubscribe();
      const { data: tableMetadata } = (yield tableMetadataFetch) as { data: TableMetadata };
      tablesMetadata = [tableMetadata];
    }

    const { searchData, onSearchComplete, gridApi } = params;

    const updatedSearchData = { ...searchData } as GridData;
    rowUpdates.forEach(({ table = selectedTable, rowData, column, value }: UpdateClientDataRow) => {
      const updateRowId = rowData[ClientDataFixedColumns.RowId];
      const updatedRow = updatedSearchData[table]?.find(({ [ClientDataFixedColumns.RowId]: id }) => id === updateRowId);
      if (updatedRow) {
        const newRow = { ...updatedRow, [column]: value };
        const matchesStillExist = Object.entries(newRow).some(([columnId, cellData]) =>
          searchColumnMatches(
            updateRowId,
            columnId,
            cellData,
            {
              clientId,
              searchValue,
              searchType,
              searchOptions,
              tableMetadata: tablesMetadata.find(({ tableName }) => tableName === table),
            },
            gridApi,
          ),
        );
        updatedSearchData[table] = [
          ...updatedSearchData[table].filter(({ [ClientDataFixedColumns.RowId]: id }) => id !== updateRowId),
          // Don't add back the row if results no longer exist in the row
          ...(matchesStillExist ? [newRow] : []),
        ];
      }
    });
    onSearchComplete({ [clientId]: updatedSearchData });
    yield put(
      setSearchResultIds(
        getSearchResultIds(
          updatedSearchData,
          [SearchType.AllTables, SearchType.AllVendorsAllTables].includes(searchType)
            ? tablesColumns
            : { [selectedTable]: tablesColumns[selectedTable] },
          { mainClientId: clientId, clientId, searchValue, searchType, searchOptions, tablesMetadata },
          gridApi,
        ),
      ),
    );

    // Get current update's IDs
    const updateIds = rowUpdates.map(
      ({ table = selectedTable, rowData, column }: UpdateClientDataRow) =>
        `${clientId}:${table}:${rowData[ClientDataFixedColumns.RowId]}:${column}`,
    );
    const previousReplacedIds = replacedResultIds.filter((id) => !replacedIds.includes(id));
    if (replaceAll) {
      // Only track replaced IDs for single replace
      yield put(setReplacedResultIds([]));
    } else if (replacedResultIds.some((id) => updateIds.includes(id))) {
      // If current update includes replaced IDs, this is an undo
      yield put(setReplacedResultIds([...previousReplacedIds]));
    } else {
      // Otherwise, this is a replace/redo
      yield put(setReplacedResultIds([...previousReplacedIds, ...replacedIds]));
    }

    // Update the selected search result to ensure it is still valid
    yield put(getSearchResultIndexAction({ increment: 0, gridApi }));

    // Update changed tables when replacing other tables
    if (replacedResultIds.some((id) => id.split(':')[1] !== selectedTable)) {
      yield put(
        clientDataApi.util.invalidateTags([
          getClientDataCacheTag(ClientDataCacheTagType.Branches, { clientDataType, clientId }),
        ]),
      );
    }
  } catch (error) {
    const { errorMessage = defaultErrorMessage } = extractErrorProps(error);
    console.error(`replaceValuesComplete: `, errorMessage);
  } finally {
    yield put(fetchSearchResultsComplete());
  }
}

function* fetchSearchResults({ payload: { onSearchComplete, gridApi, resetSearch } }: any): Generator {
  try {
    const {
      clientId,
      clientDataType,
      clientDataBranch = ClientDataBranch.Main,
      selectedTable,
      search: { value: searchValue = '', type: searchType = SearchType.AllTables, options: searchOptions = {} } = {},
    } = (yield select(({ clientData }: AppState) => clientData)) as ClientDataState;
    const { group: { groupId } = unknownGroup } = (yield select(
      ({ currentUser }: AppState) => currentUser,
    )) as CurrentUserState;

    if (searchValue) {
      let tablesColumns: { [table: string]: string[] } = {};
      const tablesColumnsFetch = (yield put(
        clientDataApi.endpoints.getClientDataTablesColumns.initiate({
          dataType: clientDataType,
          clientId,
        }) as any,
      )) as QueryActionCreatorResult<any>;
      tablesColumnsFetch.unsubscribe();
      ({ data: tablesColumns } = (yield tablesColumnsFetch) as { data: { [table: string]: string[] } });

      let tablesMetadata: TableMetadata[] = [];
      if (searchType === SearchType.AllTables) {
        const tablesMetadataFetch = (yield put(
          clientDataApi.endpoints.getClientDataAllTableMetadata.initiate({
            dataType: clientDataType,
            clientId,
            branch: clientDataBranch,
          }) as any,
        )) as QueryActionCreatorResult<any>;
        tablesMetadataFetch.unsubscribe();
        ({ data: tablesMetadata } = (yield tablesMetadataFetch) as { data: TableMetadata[] });
      } else {
        const tableMetadataFetch = (yield put(
          clientDataApi.endpoints.getClientDataTableMetadata.initiate({
            dataType: clientDataType,
            clientId,
            table: selectedTable,
            branch: clientDataBranch,
          }) as any,
        )) as QueryActionCreatorResult<any>;
        tableMetadataFetch.unsubscribe();
        const { data: tableMetadata } = (yield tableMetadataFetch) as { data: TableMetadata };
        tablesMetadata = [tableMetadata];
      }

      if (resetSearch) {
        yield put(setSearchResultIds(undefined));
        yield put(setSearchResultIndex(undefined));
        yield put(setReplacedResultIds([]));
      }

      let searchResults: { [clientId: string]: GridData } = {};
      if ([SearchType.CurrentSelection, SearchType.CurrentTable].includes(searchType)) {
        const dataFetch = (yield put(
          clientDataApi.endpoints.getClientDataTableData.initiate({
            dataType: clientDataType,
            clientId,
            groupId,
            table: selectedTable,
            branch: clientDataBranch,
          }) as any,
        )) as QueryActionCreatorResult<any>;
        dataFetch.unsubscribe();
        // Wait until the table data is fetched and the grid is updated
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
        const { data } = (yield dataFetch) as { data: TableData[] };

        if (searchType === SearchType.CurrentSelection) {
          // Wait for grid to have visible nodes as the grid might be refreshing during a current selection search
          yield waitForCondition(() => gridApi.getRenderedNodes().length > 0);
        }

        const filteredData = getAllGridData(gridApi);

        searchResults = searchCurrentTable(
          filteredData,
          {
            clientId,
            table: selectedTable,
            searchValue,
            searchType,
            searchOptions,
            tableMetadata: tablesMetadata.find(({ formattedTableName }) => formattedTableName === selectedTable),
          },
          gridApi,
        );
      } else if (searchType === SearchType.AllTables) {
        const dataFetch = (yield put(
          clientDataApi.endpoints.getClientData.initiate({
            dataType: clientDataType,
            groupId,
            clientId,
            branch: clientDataBranch,
          }) as any,
        )) as QueryActionCreatorResult<any>;
        dataFetch.unsubscribe();
        const { data: allTableData = {} } = (yield dataFetch) as { data: GridData };
        searchResults = Object.keys(allTableData).reduce((results, table) => {
          const { [clientId]: tableResults } = searchCurrentTable(
            allTableData[table],
            {
              clientId,
              table,
              searchValue,
              searchType,
              searchOptions,
              tableMetadata: tablesMetadata.find(({ formattedTableName }) => formattedTableName === table),
            },
            gridApi,
          );
          return {
            [clientId]: {
              ...results[clientId],
              ...((tableResults[table].length && tableResults) || {}),
            },
          };
        }, {} as { [clientId: string]: GridData });
      } else if (searchType === SearchType.AllVendorsCurrentTable) {
        const dataFetch = (yield put(
          clientDataApi.endpoints.searchTable.initiate({
            dataType: clientDataType,
            clientId,
            table: selectedTable,
            searchTerm: searchValue,
            branch: clientDataBranch,
          }) as any,
        )) as QueryActionCreatorResult<any>;
        dataFetch.unsubscribe();
        ({ data: searchResults = {} } = (yield dataFetch) as { data: { [clientId: string]: GridData } });
      }

      yield put(
        setSearchResultIds(
          Array.from(new Set([clientId, ...Object.keys(searchResults)])).reduce((resultIds, id) => {
            const clientData = searchResults[id] || {};
            const clientResultIds = getSearchResultIds(
              clientData,
              [SearchType.AllTables, SearchType.AllVendorsAllTables].includes(searchType)
                ? tablesColumns
                : { [selectedTable]: tablesColumns[selectedTable] },
              {
                mainClientId: clientId,
                clientId: mapClientKeyAndProductToClientId(getVendorFromClientId(id), mapClientIdToProduct(clientId)),
                searchValue,
                searchType,
                searchOptions,
                tablesMetadata,
              },
              gridApi,
            );
            return [...resultIds, ...clientResultIds];
          }, [] as string[]),
        ),
      );

      if (onSearchComplete) onSearchComplete(searchResults);
    } else {
      yield put(setSearchResultIds(undefined));
      yield put(setSearchResultIndex(undefined));
      yield put(setReplacedResultIds([]));
      if (onSearchComplete) onSearchComplete([]);
    }
  } finally {
    yield put(fetchSearchResultsComplete());
  }
}

function* watchGoToCellRange(): Generator {
  yield takeLatest(goToCellRangeAction.type, goToCellRange);
}

function* watchGetSearchResultIndex(): Generator {
  yield takeLatest(getSearchResultIndexAction.type, getSearchResultIndex);
}

function* watchReplaceValues(): Generator {
  yield takeLatest(replaceValuesAction.type, replaceValues);
}

function* watchReplaceValuesComplete(): Generator {
  yield takeLatest(replaceValuesCompleteAction.type, replaceValuesComplete);
}

function* watchFetchSearchResults(): Generator {
  yield takeLatest(fetchSearchResultsAction.type, fetchSearchResults);
}

export function* clientDataSaga(): Generator {
  yield all([
    watchGoToCellRange(),
    watchGetSearchResultIndex(),
    watchReplaceValues(),
    watchReplaceValuesComplete(),
    watchFetchSearchResults(),
  ]);
}
