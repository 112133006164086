import {
  AppBar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Fab,
  Grid2 as Grid,
  Switch,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialogs } from '../constants/Dialogs';
import { DockedDrawerWidth } from '../constants/DockedDrawerWidth';
import { openDialog, setForm } from '../ducks/dialogSlice';
import { fetchGroup, setMemberDialog, toggleEnabledOnProperty as toggleEnabledOnPropertyFunc } from '../ducks/group';
import { AppState } from '../types/AppState';
import { Configurator, ConfiguratorEnabledOnProps } from '../types/Configurator';
import { GroupMember } from '../types/GroupMember';
import { mapConfiguratorToClientId } from '../utils/clientIdUtils';
import { stringToLowerIncludes } from '../utils/stringUtils';
import { isIdeaRoomGroup, isIdeaRoomUser } from '../utils/userUtils';
import { ConfiguratorMenu } from './ConfiguratorMenu';
import { GroupMembersTable } from './GroupMembersTable';
import { Table } from './Table';
import { getEnabledOnProperty } from '../utils/vendorDataUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { useAppDispatch, useAppSelector } from '../hooks';
import { FabMenu } from './FabMenu';
import { ConfiguratorFormType } from '../constants/Configurator';
import { config } from '../config/config';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  fab: {
    position: 'fixed',
    [theme.breakpoints.down('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2) },
    [theme.breakpoints.up('lg')]: { bottom: theme.spacing(2), left: theme.spacing(2 + DockedDrawerWidth / 8) },
  },
  fabText: {
    padding: theme.spacing(1),
  },
  configuratorCards: { flex: 1, paddingBottom: '70px' },
  configuratorCard: {
    margin: theme.spacing(2),
  },
  configuratorCardHeader: { paddingBottom: '0' },
  configuratorCardContent: { paddingTop: '0' },
  configuratorCardContentLine: {
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface Props {
  groupId: string;
}

const VendorCell: React.FC<any> = ({ row, value }: any) => (
  <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
    {row.updating && <CircularProgress size={24} color="primary" />}
    {!row.updating && <ConfiguratorMenu configurator={row} />}
  </div>
);

interface SwitchCellProps {
  row: Configurator;
  value: boolean;
}

const getSwitchCell = (
  onChange: (configurator: Configurator, enabledOnProperty: ConfiguratorEnabledOnProps) => void,
  enabledOnProperty: ConfiguratorEnabledOnProps,
  supplierOnly?: boolean,
): React.FC<any> => {
  const SwitchCell: React.FC<any> = ({ row, value }: SwitchCellProps) => {
    const disabled = !!(supplierOnly && row?.vendor !== row?.supplierKey);
    return (
      <Switch
        disabled={disabled}
        checked={value && !disabled}
        onChange={(): void => onChange(row, enabledOnProperty)}
        color="primary"
      />
    );
  };
  return SwitchCell;
};

// eslint-disable-next-line no-shadow
enum GroupComponentTabs {
  Configurators = 'CONFIGURATORS',
  Members = 'MEMBERS',
}

export const Group: React.FC<Props> = ({ groupId }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [tab, setTab] = useState(GroupComponentTabs.Members);
  const [filteredGroupMembers, setFilteredGroupMembers] = useState<GroupMember[]>([]);
  const [filteredConfigurators, setFilteredConfigurators] = useState<Configurator[]>([]);

  const {
    group: { group, loading },
    search: { searchTerm },
    currentUser: { user },
  } = useAppSelector((state: AppState) => state);

  // Hook that updates when breakpoint value changes to/from xs
  const tabsVariant = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) ? 'fullWidth' : undefined;

  const dispatch = useAppDispatch();

  const toggleEnabledOnProperty = (configurator: Configurator, enabledOnProperty: ConfiguratorEnabledOnProps): void => {
    dispatch(toggleEnabledOnPropertyFunc(configurator, enabledOnProperty));
  };
  const openUserDialog = (): void => {
    dispatch(setMemberDialog());
    dispatch(openDialog({ dialog: Dialogs.User }));
  };

  // Effect that filters members by searchTerm anytime either members or searchTerm change
  useEffect(() => {
    if (group && group.members) {
      if (searchTerm.length === 0) {
        setFilteredGroupMembers(group.members);
        return;
      }
      const tests = [
        (groupMember: GroupMember): boolean => stringToLowerIncludes(groupMember.email, searchTerm),
        (groupMember: GroupMember): boolean => stringToLowerIncludes(groupMember.name, searchTerm),
      ];

      setFilteredGroupMembers(group.members.filter((groupMember) => tests.some((test) => test(groupMember))));
    } else {
      setFilteredGroupMembers([]);
    }
  }, [group, searchTerm]);

  // Effect that filters configurators by searchTerm anytime either configurators or searchTerm change
  useEffect(() => {
    if (group && group.configurators) {
      if (searchTerm.length === 0) {
        setFilteredConfigurators(group.configurators);
        return;
      }

      const tests = [
        (configurator: Configurator): boolean => stringToLowerIncludes(configurator.vendor, searchTerm),
        (configurator: Configurator): boolean => stringToLowerIncludes(configurator.key, searchTerm),
      ];

      setFilteredConfigurators(group.configurators.filter((configurator) => tests.some((test) => test(configurator))));
    } else {
      setFilteredConfigurators([]);
    }
  }, [group, searchTerm]);

  useEffect(() => {
    dispatch(fetchGroup(groupId));
  }, [groupId]);

  return (
    <div className={classes.root}>
      {!isIdeaRoomGroup(groupId) && (
        <AppBar position="static" color="inherit">
          <Tabs
            value={tab}
            onChange={(event: React.SyntheticEvent<Element, Event>, newValue: GroupComponentTabs): void => {
              setTab(newValue);
            }}
            aria-label="members and configurators tabs"
            variant={tabsVariant}
            indicatorColor="primary"
          >
            <Tab
              label={t(I18nKeys.GroupMembersTab)}
              value={GroupComponentTabs.Members}
              id="members-tab"
              aria-controls="members-tab"
            />
            <Tab
              label={t(I18nKeys.GroupConfiguratorsTab)}
              value={GroupComponentTabs.Configurators}
              id="configurators-tab"
              aria-controls="configurators-tab"
            />
          </Tabs>
        </AppBar>
      )}

      {!isIdeaRoomGroup(groupId) && tab === GroupComponentTabs.Configurators && (
        <>
          <Table
            sx={{ display: { xs: 'none', sm: 'block' } }}
            headers={[
              { i18nKey: I18nKeys.TableHeaderVendorKey, property: 'vendor' },
              {
                i18nKey: I18nKeys.TableHeaderKey,
                property: 'key',
                valueFormatter: (property: string, row: any): string =>
                  row[property] === 'carportview'
                    ? t(I18nKeys.ConfiguratorFormFieldConfiguratorCarports)
                    : t(I18nKeys.ConfiguratorFormFieldConfiguratorSheds),
              },
              { i18nKey: I18nKeys.TableHeaderName, property: 'name' },
              {
                i18nKey: I18nKeys.TableHeaderType,
                property: 'supplierKey',
                valueFormatter: (property: string, row: any): string =>
                  row[property] !== row?.vendor
                    ? t(I18nKeys.ConfiguratorFormFieldDealer)
                    : t(I18nKeys.ConfiguratorFormFieldSupplier),
              },
              {
                i18nKey: I18nKeys.TableHeaderPricing,
                property: ConfiguratorEnabledOnProps.PricingEnabled,
                CellComponent: getSwitchCell(toggleEnabledOnProperty, ConfiguratorEnabledOnProps.PricingEnabled, true),
              },
              {
                i18nKey: I18nKeys.TableHeaderDealerNetwork,
                property: ConfiguratorEnabledOnProps.DealerNetworkEnabled,
                CellComponent: getSwitchCell(toggleEnabledOnProperty, ConfiguratorEnabledOnProps.DealerNetworkEnabled),
              },
              {
                i18nKey: I18nKeys.TableHeaderTemplate,
                property: ConfiguratorEnabledOnProps.TemplateEnabled,
                CellComponent: getSwitchCell(toggleEnabledOnProperty, ConfiguratorEnabledOnProps.TemplateEnabled),
              },
              {
                i18nKey: I18nKeys.TableHeaderProSubscription,
                property: ConfiguratorEnabledOnProps.ProSubscriptionEnabled,
                CellComponent: getSwitchCell(
                  toggleEnabledOnProperty,
                  ConfiguratorEnabledOnProps.ProSubscriptionEnabled,
                ),
              },
              {
                i18nKey: I18nKeys.TableHeaderSalesTools,
                property: ConfiguratorEnabledOnProps.SalesToolsEnabled,
                CellComponent: getSwitchCell(toggleEnabledOnProperty, ConfiguratorEnabledOnProps.SalesToolsEnabled),
              },
              { i18nKey: '', property: '', CellComponent: VendorCell },
            ]}
            sortableProperties={[
              { i18nKey: I18nKeys.TableHeaderKey, property: 'key' },
              { i18nKey: I18nKeys.TableHeaderVendorKey, property: 'vendor' },
            ]}
            rows={filteredConfigurators.map((configurator) => ({
              ...configurator,
              ...(configurator?.vendorData?.vendor || {}),
            }))}
            loading={loading}
          />
          <Grid sx={{ display: { xs: 'block', sm: 'none' } }} style={{ flexDirection: 'column', height: '100%' }}>
            {filteredConfigurators.map((configurator) => (
              <Card className={classes.configuratorCard} key={mapConfiguratorToClientId(configurator)}>
                <CardHeader
                  className={classes.configuratorCardHeader}
                  action={<ConfiguratorMenu configurator={configurator} />}
                  title={configurator.name}
                />
                <CardContent className={classes.configuratorCardContent}>
                  <Box className={classes.configuratorCardContentLine}>
                    {configurator?.suppliers?.length
                      ? t(I18nKeys.ConfiguratorFormFieldDealer)
                      : t(I18nKeys.ConfiguratorFormFieldSupplier)}
                  </Box>
                  <Box className={classes.configuratorCardContentLine}>{configurator.vendor}</Box>
                  <Box className={classes.configuratorCardContentLine}>
                    {configurator.key === 'carportview'
                      ? t(I18nKeys.ConfiguratorFormFieldConfiguratorCarports)
                      : t(I18nKeys.ConfiguratorFormFieldConfiguratorSheds)}
                  </Box>
                  {[
                    {
                      label: I18nKeys.TableHeaderPricing,
                      prop: ConfiguratorEnabledOnProps.PricingEnabled,
                      supplierOnly: true,
                    },
                    {
                      label: I18nKeys.TableHeaderDealerNetwork,
                      prop: ConfiguratorEnabledOnProps.DealerNetworkEnabled,
                      supplierOnly: true,
                    },
                    {
                      label: I18nKeys.TableHeaderTemplate,
                      prop: ConfiguratorEnabledOnProps.TemplateEnabled,
                      supplierOnly: true,
                    },
                    {
                      label: I18nKeys.TableHeaderProSubscription,
                      prop: ConfiguratorEnabledOnProps.ProSubscriptionEnabled,
                    },
                    { label: I18nKeys.TableHeaderSalesTools, prop: ConfiguratorEnabledOnProps.SalesToolsEnabled },
                  ].map(({ label, prop, supplierOnly = false }) => {
                    const SwitchCell = getSwitchCell(toggleEnabledOnProperty, prop, supplierOnly);
                    return (
                      <Box className={classes.configuratorCardContentLine}>
                        <Box>{t(label)}</Box>
                        <Box>
                          <SwitchCell
                            row={configurator}
                            value={getEnabledOnProperty(configurator.vendorData, prop, true)}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </CardContent>
              </Card>
            ))}
          </Grid>
          {config.environment.STAGE === 'production' && isIdeaRoomUser(user) ? (
            <FabMenu
              icon={<AddIcon />}
              label={I18nKeys.AddButton}
              actions={[
                {
                  icon: <AddBusinessIcon />,
                  i18nKey: I18nKeys.ConfiguratorDialogFabAddNewConfigurator,
                  onClick: () => {
                    dispatch(setForm(ConfiguratorFormType.AddNewConfigurator));
                    dispatch(openDialog({ dialog: Dialogs.Configurator }));
                  },
                },
                {
                  icon: <AddIcon />,
                  i18nKey: I18nKeys.ConfiguratorDialogFabAddExistingConfigurator,
                  onClick: () => {
                    dispatch(setForm(ConfiguratorFormType.AddExistingConfigurator));
                    dispatch(openDialog({ dialog: Dialogs.Configurator }));
                  },
                },
              ]}
              className={classes.fab}
            />
          ) : (
            <Fab
              color="primary"
              className={classes.fab}
              variant="extended"
              onClick={() => {
                dispatch(setForm(ConfiguratorFormType.AddExistingConfigurator));
                dispatch(openDialog({ dialog: Dialogs.Configurator }));
              }}
              aria-label="add group"
            >
              <AddIcon />
              <Typography className={classes.fabText}>{t(I18nKeys.AddButton)}</Typography>
            </Fab>
          )}
        </>
      )}

      {tab === GroupComponentTabs.Members && (
        <>
          <GroupMembersTable loading={loading} members={filteredGroupMembers} statsAndStatus />
          <Fab
            color="primary"
            className={classes.fab}
            variant="extended"
            onClick={openUserDialog}
            aria-label="add member"
          >
            <AddIcon />
            <Typography className={classes.fabText}>{t(I18nKeys.AddButton)}</Typography>
          </Fab>
        </>
      )}
    </div>
  );
};
