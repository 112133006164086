import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CustomCellEditorProps } from 'ag-grid-react';
import { getInitialEditorValue } from '../utils/clientDataUtils';
import { ClientDataEditor } from '../constants/ClientData';
import { onEditorKeyDown } from '../utils/keyboardShortcutHandlerUtils';

export const ClientDataTextEditor: React.FC<CustomCellEditorProps> = (props: CustomCellEditorProps) => {
  const [editorValue, setEditorValue] = useState<string | undefined>(undefined);
  const [selection, setSelection] = useState<{ start: number; end: number } | undefined>(undefined);
  const refInput = useRef<HTMLInputElement | null>(null);

  const setValueFunc = useMemo(
    () => (v: string) => {
      const { onValueChange } = props;
      setEditorValue(v);
      onValueChange(v);
    },
    [props, setEditorValue],
  );

  useEffect(() => {
    refInput.current?.focus();
  }, []);

  useEffect(() => {
    if (editorValue === undefined) {
      setValueFunc(getInitialEditorValue(props));
    }
  }, [editorValue, setValueFunc, props]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) =>
      onEditorKeyDown(e, {
        editorType: ClientDataEditor.Standard,
        value: editorValue,

        insertValue: (val: string) => {
          const maxSelection = editorValue?.length || 0;
          let { selectionStart, selectionEnd } = (refInput.current || {}) as {
            selectionStart?: number;
            selectionEnd?: number;
          };
          // As a default, paste at the end of the current value
          if (selectionStart === undefined) selectionStart = maxSelection;
          if (selectionEnd === undefined) selectionEnd = maxSelection;

          const newSelectionStart = selectionStart + val.length;
          setSelection({ start: newSelectionStart, end: newSelectionStart });
          setValueFunc(
            (editorValue || '').substring(0, selectionStart) + val + (editorValue || '').substring(selectionEnd),
          );
        },

        setSelection,

        getSelection: () => {
          const { selectionStart, selectionEnd } = refInput.current || {};
          return {
            start: selectionStart || 0,
            end: selectionEnd || 0,
          };
        },
      });

    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [editorValue, refInput, setValueFunc]);

  useEffect(() => {
    // Update the selection following a paste, only after the value has been updated
    if (selection !== undefined) {
      const { start, end } = selection;
      refInput.current?.setSelectionRange(start, end);
      setSelection(undefined);
    }
  }, [editorValue, selection]);

  return (
    <div className="ag-cell-edit-wrapper">
      <div className="ag-cell-editor ag-text-field ag-input-field">
        <div className="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper">
          <input
            value={editorValue}
            ref={refInput}
            onChange={(event) => setValueFunc(event.target.value)}
            className="ag-input-field-input ag-text-field-input"
          />
        </div>
      </div>
    </div>
  );
};
