import { CellClickedEvent, Column, GridApi, CellSelectionChangedEvent } from 'ag-grid-community';
import { getCellRangeInfo } from './clientDataUtils';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';

/**
 * Clears all selections (focused cell, cell ranges, and selected rows)
 *
 * @param {GridApi} api
 * @returns {void}
 */
export const clearSelections = (api: GridApi, deselectRows = true): void => {
  api.clearFocusedCell();
  api.clearCellSelection();
  if (deselectRows) api.deselectAll();
};

export const selectRows = (
  startIndex: number,
  endIndex: number,
  columns: (string | Column)[],
  api: GridApi,
  setRowsSelected = true,
) => {
  clearSelections(api, setRowsSelected);

  api.addCellRange({
    rowStartIndex: startIndex,
    rowEndIndex: endIndex,
    columns,
  });
  const selectedColumns = columns.map((col) => (typeof col === 'string' ? col : col.getColId()));
  const displayedColumns = api.getAllDisplayedColumns().map((col) => col.getColId());
  const firstSelectedColumn = displayedColumns.find((col) => selectedColumns.includes(col));
  api.setFocusedCell(startIndex, firstSelectedColumn || selectedColumns[0]);

  if (setRowsSelected) {
    Array.from({ length: Math.abs(endIndex - startIndex) + 1 }, (_el, i) =>
      api.getDisplayedRowAtIndex(Math.min(startIndex, endIndex) + i)?.setSelected(true),
    );
  }
};

export const onCellClicked = ({ api, column, rowIndex, event }: CellClickedEvent, columns: string[]) => {
  // if the column is not the index column, return
  if (column.getColId() !== ClientDataFixedColumns.Index || (!rowIndex && rowIndex !== 0)) {
    api.deselectAll();
    return;
  }

  const { metaKey, shiftKey } = event as KeyboardEvent;
  if (metaKey || shiftKey) {
    const cellRanges = api.getCellRanges() || [];
    const { startRowIndex, endRowIndex } = getCellRangeInfo(cellRanges);
    selectRows(startRowIndex, endRowIndex, columns, api);
    return;
  }

  selectRows(rowIndex, rowIndex, columns, api);
};

export const onCellSelectionChanged = (
  { api, finished }: CellSelectionChangedEvent,
  columns: string[],
  changeSummaryData: { displayDeletedRowIds: string[]; displayDeletedRowEnded: boolean },
  clearChangeSummaryData: () => void,
) => {
  const cellRanges = api.getCellRanges() || [];
  const { startRowIndex, endRowIndex, rowCount } = getCellRangeInfo(cellRanges);
  const { displayDeletedRowIds, displayDeletedRowEnded } = changeSummaryData;
  if (
    finished &&
    displayDeletedRowEnded &&
    displayDeletedRowIds.length > 0 &&
    (rowCount !== 1 || !displayDeletedRowIds.includes(api.getDisplayedRowAtIndex(startRowIndex)?.data.rowId))
  ) {
    // Clear deleted row display when the selection changes to anything else
    clearChangeSummaryData();
  }

  // If the range selection is empty, return
  if (!cellRanges.length) return;

  // If the user is editing a cell, don't allow range selection to change
  if (api.getEditingCells().length) {
    api.clearCellSelection();
    return;
  }

  // Otherwise check to see if the range selection is a single column and that column is the index column
  const { columns: selectionColumns } = cellRanges[0];

  if (
    rowCount > 1 &&
    finished &&
    cellRanges.length === 1 &&
    selectionColumns.length === 1 &&
    selectionColumns[0].getColId() === ClientDataFixedColumns.Index
  ) {
    selectRows(startRowIndex, endRowIndex, columns, api);
  }
};
