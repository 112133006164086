import { makeStyles } from '@mui/styles';
import React from 'react';
import { Theme, Grid2 as Grid, AppBar, Toolbar, Typography, useMediaQuery, IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { usePath } from 'hookrouter';
import { useAppSelector, useAppDispatch } from '../hooks';
import { AppState } from '../types/AppState';
import { Logo } from './Logo';
import { LogoBarSearch } from './LogoBarSearch';
import { SecondaryActionsMenu } from './SecondaryActionsMenu';
import { MenuStatus, MenuWidthMap, breadcrumbsHeight } from '../constants/Viewer';
import { TopBarBreadcrumbs } from './TopBarBreadcrumbs';
import { AppRoutes } from '../constants/AppRoutes';
import { getLogoBarHeight } from '../utils/viewerUtils';
import { ClientDataTopBar } from './ClientDataTopBar';
import { TopBarSupplierSelect } from './TopBarSupplierSelect';
import { setMenuStatus as setMenuStatusFunc } from '../ducks/viewerSlice';
import { UserPreference } from '../constants/User';
import { saveUserPreferences } from '../ducks/currentUserSlice';

const useStyles = makeStyles<
  Theme,
  {
    logoBarColor: string;
    logoBarTextColor: string;
    menuStatus: MenuStatus;
    mobileView: boolean;
    path: string;
  }
>((theme: Theme) => ({
  appBar: {
    backgroundColor: ({ logoBarColor }) => logoBarColor,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: ({ mobileView, menuStatus }) => (mobileView ? '100%' : `calc(100% - ${MenuWidthMap[menuStatus]}px)`),
    height: ({ mobileView, path }) => `${getLogoBarHeight({ mobileView, path })}px`,
  },
  appBarSection: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    justifyContent: 'space-between',
    height: '100%',
    padding: ({ mobileView }) => (mobileView ? '0px' : '8px'),
  },
  drawerIcon: {
    color: ({ logoBarTextColor }) => logoBarTextColor,
  },
  breadcrumbsToolBar: {
    minHeight: `${breadcrumbsHeight}px`,
  },
}));

export const LogoBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const path = usePath();

  const {
    secondaryActions: { secondaryActions },
    search: { hideSearch },
    viewer: {
      theme: { logoBarColor = '#FFFFFF', logoBarTextColor = '#000', contactBarColor = '#F36F3A' } = {},
      menuStatus,
    },
    currentUser: { preferences: { [UserPreference.ProfilePreferences]: otherProfilePreferences = {} } = {} },
  } = useAppSelector((state: AppState) => state || {});

  const classes = useStyles({ logoBarColor, logoBarTextColor, menuStatus, mobileView, path });

  const setMenuStatus = (status: MenuStatus): void => {
    dispatch(setMenuStatusFunc(status));
    dispatch(
      saveUserPreferences({
        userPreference: UserPreference.ProfilePreferences,
        preferences: {
          ...otherProfilePreferences,
          menuStatus: status,
        },
      }),
    );
  };

  let topBarComponent = null;
  if (path.startsWith(AppRoutes.ClientData)) {
    topBarComponent = (
      <ClientDataTopBar
        open={menuStatus !== MenuStatus.Closed}
        onDrawerToggle={() => setMenuStatus(menuStatus !== MenuStatus.Closed ? MenuStatus.Closed : MenuStatus.Expanded)}
      />
    );
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      {topBarComponent || (
        <>
          <Toolbar variant="dense" className={classes.toolbar}>
            <Grid container size="grow" justifyContent="space-between">
              <Grid size="grow" className={classes.appBarSection}>
                {menuStatus === MenuStatus.Closed && (
                  <IconButton
                    aria-label="open drawer"
                    className={classes.drawerIcon}
                    onClick={(): void => setMenuStatus(MenuStatus.Expanded)}
                    size="large"
                  >
                    <Menu />
                  </IconButton>
                )}
                <Logo />
              </Grid>

              <Grid className={classes.appBarSection}>
                <LogoBarSearch />
                {secondaryActions.length > 0 &&
                  (hideSearch && secondaryActions.length === 1 ? (
                    secondaryActions
                  ) : (
                    <SecondaryActionsMenu secondaryActions={secondaryActions} />
                  ))}
              </Grid>
            </Grid>
          </Toolbar>

          <Toolbar
            variant="dense"
            className={classes.breadcrumbsToolBar}
            style={{ backgroundColor: contactBarColor }}
            disableGutters
          >
            <Grid sx={{ display: { md: 'block', lg: 'none' } }}>
              <Typography component="div" className={classes.spacer} />
            </Grid>
            <Grid container>
              {path.startsWith(AppRoutes.Pricing) ? <TopBarSupplierSelect /> : <TopBarBreadcrumbs />}
            </Grid>
          </Toolbar>
        </>
      )}
    </AppBar>
  );
};
