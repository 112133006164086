import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { OpenInNew } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useAppSelector } from '../hooks';
import { ClientDataFixedColumns } from '../constants/ClientDataFixedColumns';
import { getClientDataTableLink } from '../utils/contextMenuUtils';
import { ClientDataBranch } from '../constants/ClientDataBranch';

export const ClientDataGoToCellRangeNewTab: React.FC<ICellRendererParams> = ({
  data,
  context = {},
  api,
}: ICellRendererParams) => {
  const { groups } = useAppSelector((state) => state.groups);

  const rowId = data[ClientDataFixedColumns.RowId];
  const displayedColumns = api.getAllDisplayedVirtualColumns();
  const [col] = displayedColumns.map((column) => column.getColId());

  const { selectedTable: table } = context;

  const groupId = groups.find((group) =>
    group.configurators?.some(
      (configurator) =>
        configurator.clientId === context.clientId || `${configurator.key}-${configurator.vendor}` === context.clientId,
    ),
  )?.groupId;

  const openRowInNewTab = (group = ''): void => {
    const url = getClientDataTableLink(group, `${context.clientId}:${context.clientDataType}`, ClientDataBranch.Main, {
      start: rowId,
      end: rowId,
      columns: [col],
      table,
    });

    window.open(url, '_blank')?.focus();
  };

  return (
    <IconButton
      disabled={!groupId || !rowId || !table}
      style={{ padding: 0, height: '100%', width: '100%', display: 'flex' }}
      onClick={(event: React.MouseEvent<HTMLButtonElement | MouseEvent>): void => {
        event.stopPropagation();
        openRowInNewTab(groupId);
      }}
    >
      <OpenInNew fontSize="small" style={{ minWidth: '24px', flex: 'auto' }} />
    </IconButton>
  );
};
