/* eslint-disable no-param-reassign */
import { Dispatch } from 'redux';
import { GridApi } from 'ag-grid-community';
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { CellMetadata, PublishingResult } from '../types/ClientData';
import { ClientDataState } from '../types/ClientDataState';
import {
  SettingsOptions,
  SearchType,
  SearchOptions,
  defaultAddRowsQuantity,
  CellMetadataProperty,
} from '../constants/ClientData';
import { ClientDataType } from '../constants/ClientDataType';
import { ClientDataBranch } from '../constants/ClientDataBranch';
import { GridData, TableData } from '../types/DataGrid';
import { clientDataApi } from '../services/clientDataApi';
import { setClientDataBranchInUrl, setClientDataTableInUrl } from '../utils/clientDataUtils';
import { resetClientIds } from './viewerSlice';
import { fetchUserPreferences, fetchUserPreferencesComplete } from './currentUserSlice';

export interface UpdateClientData {
  table?: string;
  rowData: any;
  column: string;
  value: any;
}

export interface UpdateClientDataRow {
  table?: string;
  rowData: any;
  column: string;
  value: any;
  formula: string | undefined;
}

export interface UpdateClientDataMetadata {
  cellsMetadata: CellMetadata[];
  colId: string;
  rowId: string;
  metadataProperty: CellMetadataProperty;
  value: any;
}

export const InitialClientDataState: ClientDataState = {
  selectedTable: '',
  changeSummaryOpen: false,
  clientId: '',
  clientDataType: ClientDataType.Supplier,
  clientDataBranch: undefined,
  cellNote: {},
  settings: {},
  search: {
    open: false,
    expanded: false,
    value: '',
    type: SearchType.AllTables,
    options: {},
    result: {
      loading: false,
      ids: undefined,
      index: undefined,
    },
    replace: {
      value: '',
      ids: [],
    },
  },
  highlightedCell: undefined,
  filter: {
    exceptions: {},
  },
  paste: {
    formulas: true,
  },
  loadingUserPreferences: false,
  isCreatingBranch: false,
  addRowsQuantity: defaultAddRowsQuantity,
  vendorsToPublish: [],
  publishMergeResult: undefined,
  optionIconsToGenerate: undefined,
  fetchingEndpoints: {},
};

export const clientDataSlice = createSlice({
  name: 'clientData',
  initialState: InitialClientDataState,
  reducers: {
    setSelectedTable(state, action: PayloadAction<string>) {
      const { payload: selectedTable } = action;
      state.selectedTable = selectedTable;
      setClientDataTableInUrl(selectedTable);
    },
    setClientId(state, action: PayloadAction<string>) {
      const { payload: clientId } = action;
      state.clientId = clientId;
    },
    setClientDataType(state, action: PayloadAction<ClientDataType | undefined>) {
      const { payload: clientDataType = ClientDataType.Supplier } = action;
      state.clientDataType = clientDataType;
    },
    setCellNote(state, action: PayloadAction<{ rowIds: string[]; colIds: string[] }>) {
      const { payload: { rowIds = [], colIds = [] } = {} } = action;

      state.cellNote = {
        rowIds,
        colIds,
      };
    },
    clearCellNote(state) {
      state.cellNote = {};
    },
    setSearchOpen(state, action: PayloadAction<boolean>) {
      const { payload: open } = action;
      state.search.open = open;
    },
    toggleSearchExpanded(state) {
      state.search.expanded = !state.search.expanded;
    },
    setSearchResultIds(state, action: PayloadAction<string[] | undefined>) {
      const { payload: resultIds } = action;
      state.search.result.ids = resultIds;
    },
    setSearchResultIndex(state, action: PayloadAction<number | undefined>) {
      const { payload: resultIndex } = action;
      state.search.result.index = resultIndex;
    },
    setSearchValue(state, action: PayloadAction<string>) {
      const { payload: value } = action;
      state.search.value = value;
    },
    setReplaceValue(state, action: PayloadAction<string>) {
      const { payload: value } = action;
      state.search.replace.value = value;
    },
    setReplacedResultIds(state, action: PayloadAction<string[]>) {
      const { payload: resultIds } = action;
      state.search.replace.ids = resultIds;
    },
    addFilterExceptionIds(state, action: PayloadAction<string[]>) {
      const { payload: exceptionIds = [] } = action;
      const { selectedTable } = state;
      state.filter.exceptions = {
        ...state.filter.exceptions,
        [selectedTable]: [...(state.filter.exceptions[selectedTable] || []), ...exceptionIds],
      };
    },
    clearFilterExceptionIds(state) {
      const { selectedTable } = state;
      state.filter.exceptions = {
        ...state.filter.exceptions,
        [selectedTable]: [],
      };
    },
    setPaste(state, action: PayloadAction<{ formulas: boolean }>) {
      const { payload: pasteOptions = {} } = action;
      state.paste = {
        ...state.paste,
        ...pasteOptions,
      };
    },
    setSearchType(state, action: PayloadAction<SearchType>) {
      const { payload: searchType } = action;
      state.search.type = searchType;
    },
    setClientDataBranch(state, action: PayloadAction<ClientDataBranch | undefined>) {
      const { payload: branch } = action;
      state.clientDataBranch = branch;
      if (branch) {
        setClientDataBranchInUrl(branch);
      }
    },
    setCreatingBranch(state) {
      state.isCreatingBranch = true;
    },
    setCreatingBranchComplete(state) {
      state.isCreatingBranch = false;
    },
    toggleSettingOption(state, action: PayloadAction<SettingsOptions>) {
      const { payload: option } = action;

      return {
        ...state,
        settings: {
          ...state.settings,
          [option]: !state.settings[option as SettingsOptions],
        },
      };
    },
    setSearchOptions(state, action: PayloadAction<{ [key in SearchOptions]?: boolean }>) {
      const { payload: searchOptions } = action;

      return {
        ...state,
        search: {
          ...state.search,
          options: {
            ...state.search.options,
            ...searchOptions,
          },
        },
      };
    },
    fetchSearchResults(
      state,
      // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        onSearchComplete: (searchResults: { [clientId: string]: GridData }) => void;
        gridApi?: GridApi;
        resetSearch?: boolean;
      }>,
    ) {
      state.search.result.loading = true;
    },
    fetchSearchResultsComplete(state) {
      state.search.result.loading = false;
    },
    setAddRowsQuantity(state, action: PayloadAction<number>) {
      const { payload: quantity } = action;
      state.addRowsQuantity = quantity;
    },
    setLoadingUserPreferences(state, action: PayloadAction<boolean>) {
      const { payload: loading } = action;
      state.loadingUserPreferences = loading;
    },
    setHighlightedCell(state, action: PayloadAction<{ table: string; colId: string; rowId: string } | undefined>) {
      const { payload } = action;
      state.highlightedCell = payload;
    },
    setVendorsToPublish(state, action: PayloadAction<string[]>) {
      state.vendorsToPublish = action.payload;
    },
    setPublishMergeResult(
      state,
      action: PayloadAction<{
        data: PublishingResult | undefined;
        error: any | undefined;
        isSuccess: boolean;
      }>,
    ) {
      state.publishMergeResult = action.payload;
    },
    setOptionIconsToGenerate(state, action: PayloadAction<ClientDataState['optionIconsToGenerate']>) {
      state.optionIconsToGenerate = action.payload;
    },
    setChangeSummaryOpen(state, action: PayloadAction<boolean>) {
      state.changeSummaryOpen = action.payload;
    },
    addFetchingEndpoint(state, action: PayloadAction<{ promise: Promise<void>; endpointCacheKey: string }>) {
      state.fetchingEndpoints[action.payload.endpointCacheKey] = action.payload.promise;
    },
    removeFetchingEndpoint(state, action: PayloadAction<string>) {
      state.fetchingEndpoints[action.payload] = undefined;
    },
  },
  extraReducers: (builder) => {
    // Add case to reset client ID when viewer IDs are reset
    builder.addCase(resetClientIds.type, () => ({
      ...InitialClientDataState,
    }));
    builder.addCase(fetchUserPreferences.type, (state) => {
      state.loadingUserPreferences = true;
    });
    builder.addCase(fetchUserPreferencesComplete.type, (state) => {
      state.loadingUserPreferences = false;
    });
    builder.addMatcher(clientDataApi.endpoints.getClientDataTables.matchFulfilled, (state, action) => {
      const { clientId, dataType } = action.meta.arg.originalArgs;
      if (clientId === state.clientId && dataType === state.clientDataType) {
        if (!action.payload.some((table) => table.formattedTableName === state.selectedTable)) {
          // Currently selected table doesn't exist, must reset selectedTable
          state.selectedTable = '';
        }
      }
    });
  },
});

export const {
  setSelectedTable,
  setClientId,
  setClientDataType,
  setCellNote,
  clearCellNote,
  setSearchOpen,
  toggleSearchExpanded,
  setSearchResultIds,
  setSearchResultIndex,
  setSearchValue,
  setReplaceValue,
  setReplacedResultIds,
  addFilterExceptionIds,
  clearFilterExceptionIds,
  setPaste,
  setSearchType,
  setClientDataBranch,
  setCreatingBranch,
  setCreatingBranchComplete,
  toggleSettingOption,
  setSearchOptions,
  fetchSearchResults,
  fetchSearchResultsComplete,
  setAddRowsQuantity,
  setLoadingUserPreferences,
  setHighlightedCell,
  setVendorsToPublish,
  setPublishMergeResult,
  setOptionIconsToGenerate,
  setChangeSummaryOpen,
  addFetchingEndpoint,
  removeFetchingEndpoint,
} = clientDataSlice.actions;

export const updateClientData = createAction<{ rows: UpdateClientData[]; branch: ClientDataBranch }>(
  'clientData/updateClientData',
);

export const updateClientDataRows = createAction<UpdateClientDataRow[]>('clientData/updateClientDataRows');

export const addClientDataRows = createAction<{ rows: TableData[]; table?: string }>('clientData/addClientDataRows');

export const removeClientDataRows = createAction<{ rows: TableData[]; table?: string }>(
  'clientData/removeClientDataRows',
);

export const updateClientDataMetadata = createAction<UpdateClientDataMetadata[]>('clientData/updateClientDataMetadata');

export const saveClientDataStart = createAction('clientData/saveClientDataStart');

export const saveClientDataComplete = createAction('clientData/saveClientDataComplete');

export const getSearchResultIndex = createAction<{
  increment: number;
  gridApi?: GridApi;
  onComplete?: () => void;
}>('clientData/getSearchResultIndex');

export const getSearchResultIndexComplete = createAction('clientData/getSearchResultIndexComplete');

export const goToCellRange = createAction<{
  table: string;
  location: { startRowId?: string; endRowId?: string; columns: string[] };
  rootGridApi?: GridApi;
  onComplete?: () => void;
}>('clientData/goToCellRange');

export const goToCellRangeComplete = createAction('clientData/goToCellRangeComplete');

export const replaceValues = createAction(
  'clientData/replaceValues',
  (payload: {
    params: {
      gridApi?: GridApi;
    };
    onReplace: (resultIdsToReplace: string[]) => void;
    replaceAll: boolean;
  }) => ({
    payload: {
      onReplace: payload.onReplace,
      gridApi: payload.params.gridApi,
      replaceAll: payload.replaceAll || false,
    },
  }),
);

export const replaceValuesComplete = createAction(
  'clientData/replaceValuesComplete',
  (payload: {
    params: {
      searchData: GridData;
      searchValue: string;
      replaceValue: string;
      onSearchComplete: (searchResults: { [clientId: string]: GridData }) => void;
      cellMetadata: CellMetadata[];
      selectedTable: string;
      dispatch: Dispatch<any>;
      gridApi?: GridApi;
    };
    replacedIds: string[];
    rowUpdates: UpdateClientDataRow[];
    replaceAll: boolean;
  }) => ({
    payload: {
      params: payload.params,
      replacedIds: payload.replacedIds,
      rowUpdates: payload.rowUpdates,
      replaceAll: payload.replaceAll,
    },
  }),
);
