/* eslint-disable @typescript-eslint/no-empty-function */
import { AppBar, Grid2 as Grid, Theme, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { usePath } from 'hookrouter';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { AppState } from '../types/AppState';
import { useAppDispatch, useAppSelector } from '../hooks';
import { MenuStatus, MenuWidthMap, publishBarHeight } from '../constants/Viewer';
import { AppRoutes } from '../constants/AppRoutes';
import { useDeleteBranchMutation, usePublishClientDataMutation } from '../services/clientDataApi';
import { useClientDataRepo } from '../hooks/useClientDataRepo';
import { getPublishBarMessages } from '../utils/viewerUtils';
import { PricingTab } from '../constants/Pricing';
import { usePricingRepo } from '../hooks/usePricingRepo';
import { PublishBarButton } from '../types/Viewer';
import { getSharedPricingButtons, getSitesButtons } from '../utils/publishBarUtils';
import { getClientIdFromClientSupplier } from '../utils/clientIdUtils';
import { unknownGroup } from '../constants/Group';
import { LoadingButton } from './LoadingButton';
import { ClientDataBranch } from '../constants/ClientDataBranch';

const useStyles = makeStyles<Theme, { menuStatus: MenuStatus; displayPublishBar: boolean; mobileView: boolean }>(
  (theme: Theme) => ({
    appBar: {
      top: 'auto',
      bottom: 0,
      height: ({ displayPublishBar }) => `${displayPublishBar ? publishBarHeight : 0}px`,
      width: ({ menuStatus, mobileView }) => `calc(100% - ${mobileView ? 0 : MenuWidthMap[menuStatus]}px)`,
      transition: theme.transitions.create(['height', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      justifyContent: 'center',
      overflow: 'hidden',
    },
    appBarContent: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: { justifyContent: 'flex-end' },
      [theme.breakpoints.up('md')]: { justifyContent: 'space-between' },
    },
    messages: {
      flex: 1,
      flexDirection: 'column',
      width: 'auto',
    },
    section: {
      display: 'flex',
    },
    button: {
      '&:not(:first-child)': {
        margin: '0px 0px 0px 12px',
      },
    },
  }),
);

export const PublishBar: React.FC = () => {
  const { t } = useTranslation();
  const path = usePath();
  const dispatch = useAppDispatch();
  const mobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [buttons, setButtons] = useState<PublishBarButton[]>([]);

  const {
    viewer: { selectedTabId, selectedClientId, menuStatus, displayPublishBar, selectedPricingTabId: pricingTab },
    clientData: { clientId: clientDataClientId, clientDataType, clientDataBranch, isCreatingBranch },
    currentUser: { group: { groupId } = unknownGroup },
  } = useAppSelector((state: AppState) => state || {});
  const clientId = getClientIdFromClientSupplier(selectedTabId || selectedClientId);

  const [publishClientData, { isLoading: isPublishingClientData = false }] = usePublishClientDataMutation();
  const [deleteBranch] = useDeleteBranchMutation({
    fixedCacheKey: 'revert',
  });
  const {
    isInitializingSelectedTableData,
    vendorData: { locale = '', productionURL = '' } = {},
    isLoadingVendorData,
  } = useClientDataRepo({
    useSelectedTableData: true,
    useVendorData: true,
  });
  const { activeBranches } = usePricingRepo({
    useBranches: true,
  });

  const classes = useStyles({ menuStatus, displayPublishBar, mobileView });

  const { previewMessage, publishMessage } = getPublishBarMessages(path, t, pricingTab);

  // Pricing shared buttons
  useEffect(() => {
    if (!path.startsWith(AppRoutes.Pricing)) return;

    let branch;
    switch (pricingTab) {
      case PricingTab.Base:
        branch = ClientDataBranch.Pricing;
        break;
      case PricingTab.Component:
        branch = ClientDataBranch.ClientUpdate;
        break;
      case PricingTab.Surcharge:
        branch = ClientDataBranch.PricingSurcharge;
        break;
      case PricingTab.SizeBased:
        branch = ClientDataBranch.PricingSizeBased;
        break;
      default:
        break;
    }
    if (!branch) return;

    setButtons(
      getSharedPricingButtons({
        dispatch,
        t,
        clientId,
        clientDataType,
        groupId,
        branch,
        activeBranches,
        deleteBranch,
        isPublishing: isPublishingClientData,
      }),
    );
  }, [
    path,
    pricingTab,
    clientId,
    clientDataType,
    groupId,
    activeBranches,
    deleteBranch,
    isPublishingClientData,
    dispatch,
    t,
  ]);

  // Sites buttons
  useEffect(() => {
    if (!path.startsWith(AppRoutes.Sites)) return;
    setButtons(
      getSitesButtons({
        dispatch,
        t,
        clientId: clientDataClientId,
        groupId,
        clientDataType,
        clientDataBranch,
        locale,
        productionURL,
        deleteBranch,
        publishClientData,
        isInitializingSelectedTableData,
        isCreatingBranch,
        isPublishing: isPublishingClientData,
      }),
    );
  }, [
    path,
    clientDataClientId,
    groupId,
    clientDataType,
    clientDataBranch,
    locale,
    productionURL,
    deleteBranch,
    publishClientData,
    isInitializingSelectedTableData,
    isCreatingBranch,
    isPublishingClientData,
    isLoadingVendorData,
    dispatch,
    t,
  ]);

  return (
    <AppBar position="fixed" color="inherit" className={classes.appBar}>
      <Toolbar style={{ width: '100%' }}>
        <div className={classes.appBarContent}>
          <Grid sx={{ display: { xs: 'none', md: 'flex' } }} className={classes.messages}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: previewMessage,
              }}
            />
            <Typography
              dangerouslySetInnerHTML={{
                __html: publishMessage,
              }}
            />
          </Grid>
          <div className={classes.section}>
            {buttons.map(({ label, disabled, loading, onClick, emphasis }) => (
              <LoadingButton
                key={label}
                className={classes.button}
                variant={emphasis === 'primary' ? 'contained' : undefined}
                disabled={disabled}
                loading={loading}
                color={emphasis === 'primary' ? 'primary' : undefined}
                onClick={onClick}
              >
                {label}
              </LoadingButton>
            ))}
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};
