import CircularProgress from '@mui/material/CircularProgress';
import Select, { SelectProps } from '@mui/material/Select';
import Alert, { AlertColor } from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  overlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-1.2em',
    marginLeft: -12,
  },
  alert: {
    left: '15%',
    maxWidth: '85%',
  },
  desktop: {
    minHeight: '80px',
  },
  mobile: {
    minHeight: '110px',
  },
});

interface OwnProps {
  loading?: boolean;
  alerttext?: string;
  alertSeverity?: AlertColor;
}

type Props = OwnProps & SelectProps;

export const LoadingSelect: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { loading = false, alerttext, alertSeverity = 'warning', ...selectProps } = props;

  return (
    <>
      <Select
        sx={{ display: { xs: 'none', sm: 'block' } }}
        className={alerttext && classes.desktop}
        disabled={loading}
        {...selectProps} // eslint-disable-line react/jsx-props-no-spreading
      />
      <Select
        sx={{ display: { xs: 'block', sm: 'none' } }}
        className={alerttext && classes.mobile}
        disabled={loading}
        {...selectProps} // eslint-disable-line react/jsx-props-no-spreading
      />
      {loading && <CircularProgress size={24} className={classes.overlay} color="primary" />}
      {!loading && alerttext && (
        <Alert severity={alertSeverity} className={`${classes.overlay} ${classes.alert}`}>
          {alerttext}
        </Alert>
      )}
    </>
  );
};
